<template>
  <div class="new_register_page"> 

    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="selectingProvider" />

    <!-- CONTENT -->
    <div class="">
     
      <div class="">

        <!-- FORM SCROLLING -->
        <!-- <div class="form_scorlling_add_family"> -->


          <!-- HEADER -->
          <div class="mob_header_cont" >

            <!-- HEADER DSEKTOP -->
            <div class="mob_header hide_on_mobile">
              <div class="mob_header_div mob_div_pl display_flex_left">
                <img src="/svg/icon_white_arrow_left.svg" role="button" @click="$router.go(-1)"><br>
              </div>
              <div class="mob_header_div mob_div_pr display_flex_right">
                <img src="/apalywhite.svg" class="mob_header_logo"/>
              </div>
            </div>

            <!-- HEADER MOBILE -->
            <div class="mob_header hide_on_desktop">
              <div class="mob_header_div display_flex_left">
                <img src="/svg/icon_white_arrow_left.svg" role="button" @click="$router.go(-1)"><br>
              </div>
              <div class="mob_header_div display_flex_right">
                <img src="/apalywhite.svg" class="mob_header_logo"/>
              </div>
            </div>

            <!-- STEPS NO SELECTED PROVIDER-->
            <div class="mob_step_cont" v-if="!selectedProvider">
                <div class="mob_step">
                    <div class="mob_step_num mob_step_done">1</div>
                    <div class="mob_step_text">Personalize <br> your search</div>
                </div>
                <div class="mob_step_gap"></div>
                <div class="mob_step">
                    <div class="mob_step_num mob_step_done">2</div>
                    <div class="mob_step_text">Select <br> Provider</div>
                </div>
                <div class="mob_step_gap"></div>
                <div class="mob_step">
                    <div class="mob_step_num mob_step_done">3</div>
                    <div class="mob_step_text">Verify your <br> eligibility</div>
                </div>
                <div class="mob_step_gap"></div>
                <div class="mob_step">
                    <div class="mob_step_num mob_step_active">4</div>
                    <div class="mob_step_text">Add family members</div>
                </div>
                <div class="mob_step_gap"></div>
                <div class="mob_step">
                    <div class="mob_step_num ">5</div>
                    <div class="mob_step_text">Schedule your<br> first visit</div>
                </div>
            </div>

            <!-- STEPS WITH SELECTED PROVIDER-->
            <div class="mob_step_cont" v-else>
                <div class="mob_step">
                    <div class="mob_step_num mob_step_done">1</div>
                    <div class="mob_step_text">Personalize <br> your search</div>
                </div>
                <div class="mob_step_gap"></div>
                <div class="mob_step">
                    <div class="mob_step_num mob_step_done">2</div>
                    <div class="mob_step_text">Select <br> Provider</div>
                </div>
                <div class="mob_step_gap"></div>
                <div class="mob_step">
                    <div class="mob_step_num mob_step_done">3</div>
                    <div class="mob_step_text">Verify your <br> eligibility</div>
                </div>
                <div class="mob_step_gap"></div>
                <div class="mob_step">
                    <div class="mob_step_num mob_step_active">4</div>
                    <div class="mob_step_text">Add family members</div>
                </div>
                <div class="mob_step_gap"></div>
                <div class="mob_step">
                    <div class="mob_step_num mob_step_active">5</div>
                    <div class="mob_step_text">Schedule your<br> first visit</div>
                </div>
            </div>

          </div>

          <!-- TITLE -->
          <div class="mob_addfam_title mob_adjust_margin">
            <div class="mob_subhead_cont">
              Add a family member
            </div>
          </div>

          <!-- FORM SCROLLING -->
          <div class="form_scorlling_add_family">

          <!-- FORM -->
          <div class="mob_register_form" id="scrollUpForm">
            <v-form  v-model.trim="validInfo"  ref="confirmInfo" class="form_cutom_width mb-10">
              <!-- <div class="font-24 font-weight-400 mb-3">Add a family member</div><br> -->

              <p class="mb-md-7 mb-3 text-gray mt-5" style="font-size: 22px; font-weight: bold; color:#102a4d">
                Great, now let's confirm your eligibility.
              </p>

              <!-- For Subscribers -->
                <div v-if="memberInfo && memberInfo.subscriberRelation == 'Self'">
                  <label>What is the family member's relationship to the subscriber?</label>
                    <v-select
                      ref="relationships"
                      class="round-input"
                      @keydown.enter.prevent
                      v-model.trim="newMember.subscriberRelation"
                      :items="relationships"
                      item-text="text"
                      item-value="value"
                      :rules="requiredRules"
                      placeholder="Select"
                      outlined
                      dense
                    ></v-select>
                </div>

                <!-- For Dependents and Spouse -->
                <div v-else>
                  <label>What is this family member's relationship to the subscriber?</label>
                    <v-select
                      ref="relationships"
                      class="round-input"
                      @keydown.enter.prevent
                      v-model.trim="newMember.subscriberRelation"
                      :items="relationshipsDependent"
                      item-text="text"
                      item-value="value"
                      :rules="requiredRules"
                      placeholder="Select"
                      outlined
                      dense
                    ></v-select>
                </div>

                <!-- <div class="font-24 font-weight-500 mb-2">Personal Information</div> -->
                <div class="font-20 mb-2 bold_label">Family Member Information</div>

              <div>
                <label>First Name <small>(as in their Insurance Card)</small></label>
                <v-text-field
                  ref="firstName"
                  @keydown.enter.prevent
                  class="round-input"
                  v-model.trim="newMember.firstName"
                  :rules="requiredRules"
                  placeholder="Enter First Name"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Last Name <small>(as in their Insurance Card)</small></label>
                <v-text-field
                  ref="lastName"
                  @keydown.enter.prevent
                  class="round-input"
                  v-model.trim="newMember.lastName"
                  :rules="requiredRules"
                  placeholder="Enter Last Name"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Date of Birth</label>
                <v-menu
                  @keydown.enter.prevent
                  ref="menu"
                  hide-details
                  v-model.trim="calendarMenu"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  offset-y
                  min-width="auto"
                  location="start"
                  :nudge-bottom="10"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      ref="dob"
                      @keydown.enter.prevent
                      class="round-input"
                      v-model="dateFormatted"
                      @blur="newMember.dob = parseDate(dateFormatted)"
                      outlined
                      v-mask="'##/##/####'"
                      :rules="requiredRules"
                      append-icon="mdi-calendar"
                      persistent-hint
                      dense
                      v-bind="attrs"
                      placeholder="Enter Date of Birth: MM/DD/YYYY"
                      v-on="on"
                    ></v-text-field>
                  </template>
                </v-menu>
            </div>
              <div>
                <label>Gender</label>
                <v-select
                  ref="gender"
                  @keydown.enter.prevent
                  class="round-input"
                  v-model.trim="newMember.gender"
                  :items="genders"
                  item-text="text"
                  item-value="value"
                  :rules="requiredRules"
                  placeholder="Select"
                  outlined
                  dense
                ></v-select>
              </div>

              <div class="font-20 bold_label">Contact Information</div>
              <div class="font-12 font-weight-400 mb-3">Optional</div>

              <div>
                <label>Email</label>
                <v-text-field
                  ref="memberId"
                  @keydown.enter.prevent
                  @wheel.prevent
                  class="round-input"
                  v-model.trim="newMember.email"
                  :rules="emailRules"
                  placeholder="Enter Member ID from their insurance card"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Phone Number</label>
                <v-text-field
                  v-model.trim="newMember.phone"
                  maxlength="12"
                  @keydown.enter.prevent
                  @input="formatAsPhoneNumber"
                  class="round-input"
                  placeholder="Enter phone number"
                  :rules="phoneRules"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <v-checkbox v-model="useMyContact" class="checkbox_dependent">
                <template v-slot:label>
                  <span class="font-12 black-text mt-0 pt-0">
                    Use my contact information
                  </span>
                </template>
              </v-checkbox>


              <div class="font-20 bold_label">Insurance Information</div>
              <div class="font-12 font-weight-400 mb-3">From their insurance card </div>

              <div>
                <label>What is this family member's member ID?</label>
                <v-text-field
                  ref="memberId"
                  @keydown.enter.prevent
                  @wheel.prevent
                  class="round-input"
                  v-model.trim="newMember.memberId"
                  :rules="requiredRules"
                  placeholder="Enter Member ID from their insurance card"
                  outlined
                  dense
                ></v-text-field>
              </div>
              <div>
                <label>Health Plan</label>
                <v-select
                  @keydown.enter.prevent
                  class="round-input"
                  v-model.trim="newMember.planId"
                  :items="plans"
                  item-text="name"
                  item-value="planId"
                  :rules="requiredRules"
                  placeholder="Select"
                  outlined
                  dense
                ></v-select>
                </div>
            

              <div class="dialog_buttons mt-7">
                <!-- MEM 450: Canceling should direct users to Home -->
                <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click.prevent="redirect()" >
                  Cancel
                </button>
                <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" :disabled="!validInfo" @click.prevent="addMember()" >
                  Confirm Eligibility
                </button>
              </div>

            </v-form>
          </div>

          </div>

        <!-- </div> -->

      </div>

    </div>
       
    <!-- CONFIRMING ELIGIBILITY -->
    <v-dialog v-model="checkingEligibility" width="500px">

        <v-card class="dialog_layout">
          
          <v-card-title class="d-flex justify-end">
            <v-icon role="button" @click="checkingEligibility = false" v-text="'mdi-close'" slot="end"></v-icon>
          </v-card-title>

          <v-card-text class="dialog_content">
            <div class="dialog_image">
              <v-progress-circular indeterminate :size="50" color="primary" class="text-center mt-5 mb-5"></v-progress-circular>
            </div>
            <div class="dialog_title dialog_second text-center">
              We're confirming eligibility
            </div>
          </v-card-text>

        </v-card>

    </v-dialog>

    <!-- ERROR: UNABLE TO CONFIRM DEPENDENT ELIGIBILITY -->
    <v-dialog v-model="memberErrorModal" width="500px" persistent>

       <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error mb-2" style="text-align: left">
            Oops. We were not able to confirm your family member's eligibility.
          </div>
          <div class="dialog_subtext dialog_second bold_label">
            <!-- Please review and update the information to try again or contact support at <span style="color: #0069F3;">support@apaly.com</span> -->
            Please review and update the information to try again, or contact us at <strong>support@apaly.com</strong>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="redirect()" >
              Fix Later
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="reviseInfo()" >
              Review &amp; Update
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- ERROR FROM BACKEND -->
    <v-dialog v-model="memberExists" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="memberExists = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            {{ memberErrorMsg }}
          </div>

          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second_2 dialog_btn_floatLeft" @click="addAnotherDependent()" >
              Add another dependent
            </button>
            <button class="dialog_double_button dialog_btn_primary_2 dialog_btn_floatRight" @click="memberExists = false" >
              Okay
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- FAMILY MEMBER ENROLLED TO SAME PROVIDER AS SUBSCRIBER: NO PROVIDER PORTAL -->
    <!-- <v-dialog v-model="successfullyEnrolledDependent" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="goToMembershipCard()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Family member enrolled
          </div>
          <div class="dialog_subtext dialog_second">
            <b>{{ newMember.firstName }}&nbsp;{{ newMember.lastName }}</b> has access to&nbsp;{{ newMember.gender == 'm' ? 'his':'her' }}&nbsp;own personal doctor!
            <br><br>  
            The clinic will reach out to schedule {{ newMember.gender == 'm' ? 'his':'her' }} first appointment.  
            <br><br>
            Would you like to add another family member?
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="goToMembershipCard()" >
             I'm all set!
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="addAnotherDependent()" >
              Add another 
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- FAMILY MEMBER ENROLLED TO SAME PROVIDER AS SUBSCRIBER: GENERIC MODAL -->
    <v-dialog v-model="familyEnrolledSameDPC" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="goToMembershipCard()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Great..! Your family member is enrolled.
          </div>
          <div class="dialog_subtext dialog_second bold_label">
            Would you like to add another family member? 
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="goToMembershipCard()" >
             I'm all set!
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="addAnotherDependent()" >
              Add another 
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- FAMILY MEMBER ENROLLED TO SAME PROVIDER AS SUBSCRIBER: WITH PROVIDER PORTAL -->
    <!-- <v-dialog v-model="enrolledWithProviderPortal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Family member enrolled
          </div>
          <div class="dialog_subtext dialog_second">
            <b>{{ newMember.firstName }}&nbsp;{{ newMember.lastName }}</b> has access to&nbsp;{{ newMember.gender == 'm' ? 'his':'her' }}&nbsp;own personal doctor!
            <br><br> 
            Now let's connect you to <b>{{ memberInfo.location ? memberInfo.location.name:'' }}</b> member portal, so you can schedule your family member's first appointment
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="openCustomLink()" >
              Connect me now
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- Custom clinic redirect modal -->
    <v-dialog v-model="ifOneMedical" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="goAccountSettings()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success! You're enrolled with {{ memberInfo && memberInfo.location ? memberInfo.location.name : ''}}
          </div>
          <div class="dialog_subtext dialog_second">
            <!-- {{ postMemberEnrollment !== null ? postMemberEnrollment.message : '' }} -->
            You now have access to your own personal provider! <br> <br>
            Now let's connect you to {{ memberInfo && memberInfo.location ? memberInfo.location.name : ''}} member portal, so you can create your patient account and schedule your first appointment 
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="goToCustomRedirect(postMemberEnrollment.redirect)" >
              Connect me now
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- SUCCESS: ADDED A FAMILY MEMBER -->
    <v-dialog v-model="memberSuccessModal" width="450px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success..! You've added a family member
          </div>
          <div class="dialog_subtext dialog_second bold_label">
            Now, let's select their provider.
          </div><br>
          <div class="dialog_buttons_block" style="margin-top:-15px;">
            <button class="dialog_double_button dialog_btn_primary_3 dialog_btn_floatLeft" @click="enrollSameDPC()" >
              <!-- Select the same APC as main account  -->
              Select the same provider
            </button>
            <button class="dialog_double_button dialog_btn_second_3 dialog_btn_floatRight" @click="findAnotherProvider()" >
              Find another provider
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- SUCCESS: ADDED A FAMILY MEMBER NO DPC -->
    <v-dialog v-model="memberSuccessModalNoDPC" width="450px" persistent>

       <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success! You've added a family member
          </div>
          <div class="dialog_subtext dialog_second">
            Now let's select their provider
          </div><br>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="addAnotherDependent()" >
              Add another dependent
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="findAnotherProvider()" >
              Select provider
            </button>
          </div>

        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- DEPENDENT PLAN INFO CONFIRMATION -->
    <v-dialog v-model="optionsForDependent" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary" style="text-align: left">
            Success..! You added a family member
          </div>
          <div class="dialog_subtext2 dialog_second">
            <!-- Unfortunately, the provider you are enrolled with does not offer pediatric services. Go to the marketplace to select another provider for your family member.  -->
            <!-- <b>Unfortunately, the provider you are enrolled with does not offer pediatric services. Go to the marketplace to select another provider for your family member.</b>  -->
            <b>Unfortunately, the provider that you had previously selected does not offer pediatrics.  Please search for a pediatric provider for your family member.</b> 
            <!-- <br><br> If you'd like to change your clinic attribution, <strong>go to account settings.</strong> -->
            <br><br> If you'd like to select a new provider for yourself, please go to Account Settings.
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="findAnotherProvider()" >
              Find a pediatric provider
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- ALL SET -->
    <v-dialog v-model="allSetModal" width="500px" persistent>

      <v-card class="dialog_layout">
        
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success! You're enrolled with {{ memberInfo && memberInfo.location ? memberInfo.location.name : ''}}
          </div>
          <div class="dialog_subtext dialog_second">
            You now have access to your own personal provider! <br><br>
            The clinic will reach out to schedule your first appointment.  
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="redirect()" >
              Okay
            </button>
          </div>
        </v-card-text>

      </v-card>

    </v-dialog>

    <!-- WRONG DATA ENTRY -->
    <!-- <v-dialog v-model="wrongDataEntry" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="reviseInfo()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            Oops! We could not confirm eligibility
          </div>
          <div class="dialog_subtext dialog_second">
            Please review and update the information to try again or contact support at <strong> support@apaly.com </strong>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="redirect()" >
              Fix Later
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="reviseInfo()" >
              Revise information
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- MEMBER NOT ACTIVE -->
    <!-- <v-dialog v-model="memberNotActive" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="reviseInfo()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            Oops! It seems that you are not eligible on this plan
          </div>
          <div class="dialog_subtext dialog_second">
            Please review the information to try again or contact your health plan administrator to ensure you are eligible for this benefit
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="redirect()">
              Cancel
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="reviseInfo()" >
              Revise information
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- SYSTEM ERROR -->
    <!-- <v-dialog v-model="systemError" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error">
            Oops! It seems our system is down
          </div>
          <div class="dialog_subtext dialog_second">
            Your account has been created and your information is saved. Login to the app later to confirm your eligibility
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="redirect()" >
              Try again later
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- Are you sure -->
    <!-- <v-dialog v-model="areYouSure" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="areYouSure = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            Are you sure you want to cancel? If you go back, you will have confirm eligibility to enroll with a provider
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="accountCreated = true" >
              Yes, cancel
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="areYouSure = false" >
              No, continue
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- Account Created but not Eligibible -->
    <!-- <v-dialog v-model="accountCreated" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Ok! Your account is created,  but your eligibility is pending. You can confirm eligibility any time to enroll with your provider.
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="redirect()" >
              Got it
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- Generic Error Modal -->
    <v-dialog v-model="genError" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="genError = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            {{ genErrorMess ? genErrorMess:'Oops. Something went wrong' }}
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="genError = false" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
var _paq = window._paq || [];
_paq.push(['trackPageView']);
import api from "@/services/api";
import moment from "moment";
import FullscreenLoader from '../../../Common/FullscreenLoader.vue';
import { mapState } from "vuex";
export default {
    props: {
      dependentModal: {
        type: Boolean,
      },
    },
    components: {
      FullscreenLoader,
    },
    data() {
      return {
        // accountCreated: false,
        // areYouSure: false,
        // memberNotActive: false,
        useMyContact: false, 
        wrongDataEntry: false,
        systemError: false,
        selectingProvider: false,
        familyEnrolledSameDPC: false,
        genError: false,
        genErrorMess: "",
        selectedProvider: [],
        erroMessage: "",
        somethingWrong: false,
        validInfo: false,
        newMember: {
          dob: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        },
        reconfirmingEligibility: false,
        postMemberEnrollment: null,
        ifOneMedical: false,
        checkingEligibility: false,
        memberSuccessModalNoDPC: false,
        confirmMemberPlan: false,
        memberErrorModal: false,
        memberExists: false,
        // successfullyEnrolledDependent: false,
        // enrolledWithProviderPortal: false,
        optionsForDependent: false,
        memberErrorMsg: "",
        calendarMenu: false,
        dateFormatted: "",
        formattedPhoneNumber: "",
        confirmMember: {},
        confirmFormattedPhoneNumber: "",
        confirmDateFormatted: "",
        memberSuccessModal: false,
        dependentId: "", 
        reviseDependentInfo: false,
        providerId: "",
        locationId: "",
        memberError: false,
        confirmMemberErrorModal: false,
        confirmMemberSuccessModal: false,
        confirmSemafor: true,
        addMemberSemafor: true,
        confirmMemberSemafor: true,
        allSetModal: false,
        plans: [],
        genders: [
          { text: "Female", value: "f" },
          { text: "Male", value: "m" },
        ],
        relationships: [
          { text: "Spouse", value: "Spouse" },
          { text: "Dependent", value: "Dependent" },
        ],
        relationshipsDependent: [
          { text: "Dependent", value: "Dependent" },
          { text: "Self", value: "Self" },
          { text: "Spouse", value: "Spouse" },
        ],
        requiredRules: [(v) => !!v || "This field is required"],
        phoneRules: [
          (v) =>
            /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
            "Phone number must be in a valid format (XXX XXX XXXX)",
        ],
        emailRules: [
          (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
        ],
      };
    },
    watch: {
    "useMyContact"(nVal) {
      if(nVal == true) {
        console.log("Get contact info from subscriber");
        this.newMember.email = this.memberInfo.email;
        this.newMember.phone = this.memberInfo.phone;
      }
      else {
        console.log("Remove contact info");
        this.newMember.email = "";
        this.newMember.phone = "";
      }
    },
    "newMember.dob"(val) {
      if(val || val != "") {
        return this.dateFormatted = moment.utc(this.newMember.dob).format("MM/DD/YYYY");
      }
      else {
        return ""
      }
    },
    "confirmMember.dob"(val) {
      this.confirmDateFormatted = moment.utc(this.confirmMember.dob).format("MM/DD/YYYY");
    },
  },
  mounted() {
    // MEM 464 | Populate plan field for second dependent being added
    this.newMember.planId = this.memberInfo.planId;

    // this.scrollUpForm();
  },
  computed: {
    ...mapState({
      memberInfo: (state) => state.memberInfo, 
      memberPlan: (state) => state.memberPlan, 
    }),
  },
  created() {
    // Get the family member details from store for eligibility re-check
    this.getPlans();
    this.getFamilyDetails();
    this.getHomeInformation();
    this.scrollUpForm();

    this.selectedProvider = this.$store.getters.getSelectedProvider;
    // this.getaccountInformation();

    // User added a fam member so the steps in Membership Card page should have the
    // "Add Family Member" step as well
    localStorage.setItem("addFam", "yes");
  },
  methods: {
    formatAsPhoneNumber(event) {
      let number = event.replace(/\D/g, "");
      if (number.length > 10) {
        number = number.slice(0, 10);
      }
      number = number.slice(0, 3) + " " + number.slice(3, 6) + " " + number.slice(6);
      
      this.newMember.phone = number;
    },
    clearFields() {
      // Clear Fieds / Default value
      this.newMember.subscriberRelation = "";
      this.newMember.firstName  = "";
      this.newMember.lastName  = "";
      this.newMember.gender  = "";
      this.newMember.memberId = "";

      // Clearing Date field
      this.dateFormatted = "";

      // Reset rules (avoid the fields from turning red)
      this.$refs.relationships.resetValidation();
      this.$refs.firstName.resetValidation();
      this.$refs.lastName.resetValidation();
      this.$refs.dob.resetValidation();
      this.$refs.gender.resetValidation();
      this.$refs.memberId.resetValidation();
    },
    goToMembershipCard() {
      this.$router.push('/membership').then(() => {
        window.location.reload();
      });
    },
    openCustomLink() {
      if(this.memberInfo && this.memberInfo.providerPortal) {
        window.open(this.memberInfo.providerPortal, '_blank');
      }
    },
    redirect() {
      //Get redirect destination from localStorage
      let redirect = localStorage.getItem('redirect');

      // Empty Dependent ID from Store
      this.$store.commit("setDependent", "");

      //See where to redirect
      if(redirect == "/home") {
        this.$router.push('/home')
      }
      else if(redirect == "/account/details") {
        this.$router.push('/account/details')
      }
      else {
        //there is also the case when is comes from magic link and there is no saved redirect destination
        this.$router.push('/marketplace')
        // console.log("Redirect is empty: ", redirect);
      }
    },
    getFamilyDetails() {
      // Get from state
      let fam = this.$store.state.familyMemberData;
      if(fam && fam.length !== 0) {
        // console.log("fam: ", fam);
        this.newMember.subscriberRelation = fam.subscriberRelation
        this.newMember.firstName = fam.firstName
        this.newMember.lastName = fam.lastName
        this.newMember.dob = fam.dob
        this.newMember.gender = fam.gender
        this.newMember.memberId = fam.memberId
        this.newMember.planId = fam.planId

        // This will make use of the actual member's ID instead of the insurance ID
        this.dependentId = fam.id
        this.reconfirmingEligibility = true
      }
      else {
        // console.log("Reconfirming Dependent Eligibility: state.familyMemberData is empty");
      }
    },
    goToCustomRedirect(redirect) {
      window.open(redirect, '_blank');
      this.goAccountSettings();
    },
    // checkCustomModal() {
    //   // Hide the current modal first
    //   this.successfullyEnrolledDependent = false;

    //   console.log("checkCustomModal()");

    //   // if user will add family members, or clicks on 'Im all set',
    //   // frontend needs to call this endpoint to determine if we need to
    //   // show the custom modal with redirect link or not
    //   api()
    //     .get(`/member/app/provider/${this.providerId}/postenrollment`) // this.providerId is populated in created
    //     .catch((err) => {
    //       // We should handle error, soon
    //       // Ex. There was an error while retrieving your post enrollment data.
    //       // Please contact support, thank you.
    //       console.log("Custom Modal Error");
    //       console.log(err.response.data);

    //       // TEMPORARY: If there's an error, go to default function
    //       this.finishedEnrollment();
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       if(res) {
    //         if(res.data.postMemberEnrollment !== null) {
    //           this.postMemberEnrollment = res.data.postMemberEnrollment;
    //           this.ifOneMedical = true;
    //           console.log("With Redirect link");
    //         }
    //         else {
    //           console.log("Custom Modal Empty???");
    //           // console.log(err.response.data);
    //           // If custom modal isn't needed to be displayed
    //           this.finishedEnrollment();
    //         }
    //       }
    //       else {
    //         console.log("No Custom Modal");
    //         // If custom modal isn't needed to be displayed
    //         this.finishedEnrollment();
    //       }
    //     });
    // },
    findAnotherProvider() {
      // console.log("Find another provider");
      // console.log(this.dependentId);
      // console.log(this.$route.query);
      this.$store.commit("setEnrollmentStatus", false);
      this.$store.commit("setDependent", this.dependentId);
      this.$store.dispatch('getMarketplace', this.$route.query)
      this.$router.push('/marketplace')
      // this.$router.push('/marketplace').then(() => {
      //   // Reload the page after the navigation is complete
      //   // This will make the sidebar appear
      //   window.location.reload();
      // });
    },
    // goHome(){
    //   // window.location = '/home';
    //   this.$router.push('/home')
    //   // this.$router.push('/home').then(() => {
    //   //   // Reload the page after the navigation is complete
    //   //   // This will make the sidebar appear
    //   //   window.location.reload();
    //   // });
    // },
    goAccountSettings(){
      // window.location = '/home';
      this.$router.push('/account/details')
      // this.$router.push('/account/details').then(() => {
      //   // Reload the page after the navigation is complete
      //   // This will make the sidebar appear
      //   window.location.reload();
      // });
    },
   cancelNewMember(){
       this.dependentModal = false;
       this.$emit("closeDependentModal");
   },
    closeMemberModal(){
        this.dependentModal = false;
        this.$emit("closeDependentModal");
    },
    addingMemberFromSettings(memberID) {
      //See if adding family member is from Account Settings
      let redirect = localStorage.getItem('redirect');

      if(redirect == "/account/details") {
        // Show only the added family member's card
        // by storing the memberId in localStorage (for now)
        const cards = JSON.parse(localStorage.getItem('cards')) || [];

        // console.log("memberID: ", memberID);

        const newCard = { memID: memberID }; 
        // console.log("newCard ", newCard);
        
        cards.push(newCard);
        // console.log("cards ", cards);

        // Store the updated array into localStorage
        localStorage.setItem('cards', JSON.stringify(cards));
      }
    },
    addMember() {
      // this.addMemberModal = false;
      if(!this.addMemberSemafor) return
      this.checkingEligibility = true;
      this.addMemberSemafor = false

      // console.log('this.dependentId ', this.dependentId);
      // console.log('this.newMember.memberId ',this.newMember.memberId);
      // this.newMember.payerId = this.memberInfo.payerId;
      // See if it's a reconfirmation of eligibily or if info needs to be revised
      if(this.reviseDependentInfo || this.reconfirmingEligibility){ // Dependent Reconfirming Clgibility
        api()
          .post(`/member/app/family/member/${this.dependentId}/confirm/info`, this.newMember)
          .then((res) => {
            if (res) {

              // See if being added from Account Settings
              this.addingMemberFromSettings(this.dependentId);

              //Dispatch getMemberInfo
              this.$store.dispatch('getMemberInfo');

              // MEM 464 | Populate plan field for second dependent being added
              this.$store.commit('setMemberPlan', this.newMember.planId);

              this.checkingEligibility = false;
              // this.addMemberModal = false;
              // console.log("eligibility: ", res.data.eligibility.code);
              if (res.data.eligibility && res.data.eligibility.code == 200) {
                // console.log('provider details',this.providerId,this.locationId)
                if(this.providerId == null && this.locationId == null){ // No Provider
                  this.memberSuccessModalNoDPC = true;
                }else{
                  if(res.data.pediatric && res.data.pediatric.covered == false){ // Provider does not offer pedia
                    this.optionsForDependent = true;
                    // console.log('res.data.pediatric',res.data.pediatric)
                  }
                  else{
                    this.memberSuccessModal = true; // Provider offers pedia
                  }
                }
                this.reviseDependentInfo = false // Need to revise info

                // Clear Fields
                this.clearFields();
              }
              else {
                // Errors
                this.memberErrorModal = true;
                // console.log("res.eligibility: ", parseInt(res.data.eligibility));
                // // Custom Error Messages
                // if(res.data.eligibility && res.data.eligibility.code == 401) {
                //   this.memberNotActive = true;
                // }
                // else if(res.data.eligibility && res.data.eligibility.code == 400) {
                //   this.wrongDataEntry = true;
                // }
                // else if(res.data.eligibility && res.data.eligibility.code == 500) {
                //   this.systemError = true;
                // }
              }
            }
          })
          .catch((err) => {
            if (err) {
              this.checkingEligibility = false;
              // console.log(err.response.data.message);
              // this.erroMessage = err.response.data.message;
              // this.somethingWrong = true;
              // this.$swal(err.response.data, "", "error");
              this.genError = true;
              this.genErrorMess = err.response.data.message ? err.response.data.message : err.response.data;

            }
          })
          .finally(() => {
            this.checkingEligibility = false;
            this.addMemberSemafor = true
          })
      }
      else { // Adding new member
        api()
        .post(`/member/app/family/member`, this.newMember)
        .then((res) => {
          if (res) {
            // alert("/member/app/family/member res is NOT empty");
            // this.addMemberModal = false;
            this.dependentId = res.data.id;
            this.reviseDependentInfo = true

            // MEM 464 | Populate plan field for second dependent being added
            this.$store.commit('setMemberPlan', this.newMember.planId);

            api()
              .post(`/member/app/family/member/${res.data.id}/confirm/info`, this.newMember)
              .then((res) => {

                // See if being added from Account Settings
                this.addingMemberFromSettings(this.dependentId);

                //Dispatch getMemberInfo
                this.$store.dispatch('getMemberInfo');

                // alert("res is NOT empty");
                // console.log("Confirm Info: ", res);
                if (res) {
                  this.checkingEligibility = false;

                  // this.addMemberModal = false;
                  if (res.data.eligibility && res.data.eligibility.code == 200) {

                    if(this.providerId == null && this.locationId == null){ // No Provider
                      this.memberSuccessModalNoDPC = true;
                    // alert("new member is eligible but no provider");
                    }
                    else{
                      if(res.data.pediatric && res.data.pediatric.covered == false) { // Provider does not offer pedia
                        this.optionsForDependent = true;
                      }
                      else {
                        this.memberSuccessModal = true; // Provider offers pedia
                        // alert("new member is eligible");
                      }
                    }
                    this.reviseDependentInfo = false // Need to revise info
                    // alert("new member revise info");

                    // Clear Fields
                    this.clearFields();
                  }
                  else {
                    // Errors
                    this.memberErrorModal = true;
                    // console.log("res.eligibility: ", parseInt(res.data.eligibility));
                    // // Custom Error Messages
                    // if(res.data.eligibility && res.data.eligibility.code == 401) {
                    //   this.memberNotActive = true;
                    // }
                    // else if(res.data.eligibility && res.data.eligibility.code == 400) {
                    //   this.wrongDataEntry = true;
                    // }
                    // else if(res.data.eligibility && res.data.eligibility.code == 500) {
                    //   this.systemError = true;
                    // }
                  }
                }
              })
              .catch((err) => {
                // alert("err 1 is NOT empty");
                if (err) {
                  this.checkingEligibility = false;
                  // alert("error 1: "+err.response.data);
                  // console.log("error: " + err.response.data);
                  // this.$swal(err.response.data, "", "error");
                  // this.somethingWrong = true;
                  this.genError = true;
                  this.genErrorMess = err.response.data.message ? err.response.data.message : err.response.data;
                }
              })
              .finally(() => {
                this.checkingEligibility = false;
                this.addMemberSemafor = true
                // alert("addMemberSemafor = true");
              })
          }
        })
        .catch((err) => {
          // alert("err 2 is NOT empty");
          if (err) {
            // console.log("Adding Member Error: ", err.response.data);
            this.checkingEligibility = false;
            this.memberExists = true;

            if(err.response.data.message) {
              this.memberErrorMsg = err.response.data.message;
            }
            else {
              this.memberErrorMsg = err.response.data;
            }

            // Clear the fields
            this.clearFields();
          }
        });
      }
    
    },
    addAnotherDependent() {
      // this.successfullyEnrolledDependent = false;
      this.familyEnrolledSameDPC = false;
      this.memberSuccessModalNoDPC = false;
      this.memberExists = false;

       // Auto scroll up the form
      this.scrollUpForm();
    },
    cancelOptions() { 
      // this.$refs.confirmMemberRef.reset();
      // this.confirmDateFormatted = "";
      // this.confirmFormattedPhoneNumber = "";
      this.dependentId = "";
      this.optionsForDependent = false;
      this.memberSuccessModal = false;
      this.confirmMemberPlan = false;
     
    },
    // cancelOptionsNewMember() {
    // //   this.$refs.confirmInfo.reset();
    // //   this.dateFormatted = "";
    // //   this.formattedPhoneNumber = "";
    // //   this.dependentId = "";
    // //   this.memberSuccessModal = false;
    // //   this.dependentModal = false;
    // //   this.memberSuccessModalNoDPC = false;
    // //   if(window.location.pathname == '/account/details'){
    // //      window.location.reload();
    // //   }
    // //  else if(window.location.pathname == '/confirm-plan-information'){
    // //   this.$router.push('/account/details')
    // //  }
    // //  else{
    //   // this.$router.push('/marketplace')
    // //  }

    // this.$router.push('/marketplace');
    // // this.$router.push('/marketplace').then(() => {
    // //   // Reload the page after the navigation is complete
    // //   // This will make the sidebar appear
    // //   window.location.reload();
    // // });
     
    // },
    

    async scrollUpForm() {
      // Clear Form
      // await this.$refs.confirmInfo.reset();

      // Scroll Up
      // document.getElementById("scrollUpForm").scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      // set Plan ID 
      // console.log("On scroll up, this.memberPlan value:", this.memberPlan);
      if(this.memberPlan) {
        this.newMember.planId = this.memberPlan;
      }
      // console.log("On scroll up, this.newMember.planId value:", this.newMember.planId);
    },

    finishedEnrollment() {
      this.successfullyEnrolledDependent = false;
      this.allSetModal = true;
    //   this.dependentId = "";
    //   this.$store.commit("setDependent", "");
    //   if(window.location.pathname == '/account/details'){
    //      window.location.reload();
    //   }
    //  else if(window.location.pathname == '/confirm-plan-information'){
    //   this.$router.push('/account/details')
    //  }
    //  else{
    //   this.$router.push('/marketplace')
    //  }
    },
    enrollSameDPC() {
      // Loader
      this.selectingProvider = true;
      // console.log("Enroll same provider");
      this.memberSuccessModal = false;
      // this.dependentId = this.$store.getters.getDependentId;
      let data = {
        providerId: this.providerId,
        locationId: this.locationId,
      };
      api()
        .post(`/member/app/family/member/${this.dependentId}/set/provider`, data)
        .catch((err) => {
          if (err) {
            // this.$swal(err.response.data, "", "error");
            // this.somethingWrong = true;
            this.selectingProvider = false;
            this.genError = true;
            this.genErrorMess = err.response.data.message ? err.response.data.message : err.response.data;
          }
        })
        .then((res) => {
          // Hide Loader
          this.selectingProvider = false;
        
          // Dispatch getMemberInfo
          this.$store.dispatch('getMemberInfo');
          if (res) {

            // See if Subscriber's provider has cutsom link
            // if(this.memberInfo && this.memberInfo.providerPortal) { // Provider has no custom portal link
            //   this.enrolledWithProviderPortal = true;
            // }
            // else {
            //   this.successfullyEnrolledDependent = true; // Provider has custom portal link
            // }

            // Generic Modal
            this.familyEnrolledSameDPC = true;

            // others
            this.dependentId = "";
            this.optionsForDependent = false;
            this.confirmMemberPlan = false;
            this.dependentModal = false;
          }
        });

      // Custom One Modal should be triggered her is the selected DPC is One Medical
    },
    reviseInfo() {
      this.errorMsg = false;
      this.memberError = false;
      this.messages = false;
      this.dependentModal = true;

      // this.memberNotActive = false;
      // this.wrongDataEntry = false;
      // this.systemError = false; // not yet used

      this.memberErrorModal = false;
      this.addMemberModal = true;
    },
    // reviseMember() {

    //   // this.memberErrorModal = false;
    //   // this.addMemberModal = true;
    // },
    cancelNewMember() {
      this.addMemberModal = false;
    },
    getHomeInformation() {
      api()
        .get(`/member/app/home`)
        .then((res) => {
          if (res) {
            this.providerId = res.data.providerId;
            this.locationId = res.data.locationId;
          }
        })
        .catch((err) => {
          // console.log("err", err);
        });
    },
    getaccountInformation() { 
      api()
        .get(`/member/app/account`)
        .then((res) => {
          if (res) {
            this.user = res.data.personal;
            this.role = res.data.subscriberRelation;
            this.newMember.companyName = this.user.companyName;
            this.newMember.planId = this.user.planID;
            this.newMember.payerId = this.user.payerID;
            this.newMember.groupId = this.user.groupID;
            this.newMember.memberId = this.user.memberID;
            // console.log("getaccountInformation: ", res.data);
          }
        })
        .catch((err) => {
          // console.log("err", err);
        });
    },
    getPlans() {
      api()
        .get(`/member/app/plans`)
        .then((res) => {
          if (res) {
            this.plans = res.data;

            // MEM 464 | 
            // this.newMember.planId = this.memberPlan;
            this.newMember.planId = res.data[0].planId;
            this.newMember.groupId = res.data[0].groupId;
            this.newMember.payerId = this.memberInfo.payers[0].payerId;
            setTimeout(() => {
              // console.log("this.newMember: ", this.newMember);
              // console.log("getPlans: ", res.data[0]);
              // console.log("memberInfo: ", this.memberInfo.payers[0].payerId);
            }, 100);
          }
        })
        .catch((err) => {
          // if (err.response.data) {
          //   this.error = err.response.data;
          // } else {
          //   this.error = "Something went wrong. Please try again later.";
          // }
        });
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    },
   
  };
  </script>
  
  <style scoped lang="scss">
  
  .register-page-container {
    width: 100%;
  }
  .register-page {
    // border: 1px solid red;
    padding: 20px;
    margin-top: 0px;
    height: 100vh;
    // border: 1px solid red;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .hide-desktop{
  display: none !important;
}
  .round-input{
    border-radius: 8px;
  }
   label{
    color: #333333 !important;
    font-weight: 400; 
  }
.family-page {
    padding: 64px;
    margin-top: 5vh;
  }
  .apaly-logo {
    width: 130px;
  }
  .family-page-container {
    margin: 0 auto;
  }
  .family-page-logo {
    left: 30px;
  }
  .family-page-title {
    text-align: center;
  }
  .family-page-footer {
    padding: 0 140px;
    margin-top: 10px;
  }
 
  .family-page-left-side {
    background-color: #214a82;
    padding: 50px 100px 0px 100px;
    flex-direction: column;
    justify-content: space-between;
    &-image {
      border: 30px solid rgba(255, 255, 255, 0.1);
  
      border-radius: 10px;
    }
    &-logo {
      img {
        width: 120px;
      }
    }
    &-text {
      color: white;
    }
    &-footer-text {
      color: white;
    }
  }
  .family-page-right-side {
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .family-page-right-side-form {
    width: 50%;
  align-self: center;
  padding-left: 20px;
  }
  .form-button {
    display: flex;
    justify-content: flex-end;
    .primary {
      flex: 0.5;
    }
  }
  @media only screen and (max-width: 600px) {
    .apaly-logo{
    height: 40px;
    width: 70px;
  }
  .hide-desktop{
  display: flex !important;
}
  .hide-mobile{
    display: none !important;
  }
    .router-view {
      padding-top: 0px !important;
    }
    .family-page-logo {
    display: flex;
    justify-content: space-between;
    }
    .family-page {
      padding: 24px;
      margin-top: 0px;
    }
    .family-page-header {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
    .family-page-container {
      width: 100%;
    }
    .family-page-footer {
      padding: 0;
      margin-top: 20px;
    }
    
    .family-page-right-side-form {
      width:100%;
      padding: 0;
    }
    .family-page-right-side {
      flex-direction: column-reverse;
    }
  }
  </style>
  