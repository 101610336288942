<template>
  <div class="login-page">
    <div class="login-page-header text-right">
      <span>Are you a Provider or TPA?</span>
      <a href="https://apaly.net" target="blank">
        <span class="link-text">
          Access here
          <svg
            width="18"
            height="18"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.85789 3.76756L7.59273 4.03269C7.41701 4.20841 7.41701 4.49334 7.59273 4.6691L14.9237 12.0001L7.59273 19.3312C7.41701 19.5069 7.41701 19.7918 7.59273 19.9676L7.85789 20.2327C8.03362 20.4084 8.31855 20.4084 8.49427 20.2327L16.4087 12.3183C16.5844 12.1426 16.5844 11.8577 16.4087 11.6819L8.49427 3.76756C8.31855 3.5918 8.03362 3.5918 7.85789 3.76756Z"
              fill="#0069F3"
            /></svg
        ></span>
      </a>
    </div>
    <div class="login-page-container">
      <!-- <div class="login-page-logo">
        <img class="apaly-logo" src="/apaly-logo.png" alt="">
      </div> -->
      <div class="login-page-title">
        <div class="font-size-24 mb-10 font-weight-400" style="font-size: 24px">
          Member Login
        </div>
      </div>
      <div class="login-page-form">
        <v-form
          style="width: 100%"
          v-model.trim="valid"
          ref="formLogin"
          class="authentication-page-right-side-form"
        >
          <div>
            <label>Email</label>
            <v-text-field
              outlined
              dense
              type="email"
              :messages="
                !emailExists
                  ? 'There is no account associated with that email.'
                  : !emailVerified
                  ? 'Email verification still pending.'
                  : ''
              "
              @blur="lowerCaseEmail()"
              v-model="email"
              :rules="emailRules"
            ></v-text-field>
            <div
              v-if="!emailExists"
              class="text-under-input"
              @click="$router.push({ name: 'MemberRegister' })"
            >
              Switch to register >
            </div>
            <div
              v-else-if="!emailVerified"
              class="text-under-input"
              @click="resendVerificationLink"
            >
              Resend verification link >
            </div>
          </div>
          <div>
            <label>Password</label>
            <v-text-field
              outlined
              dense
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              v-model="password"
              :rules="passwordRules"
            ></v-text-field>
            <!-- <div v-if="incorrectPassword" class="text-under-input" @click="navigateToConfirmEmail" >Reset Password ></div> -->
          </div>
          <div class="forget-password-text">
            <p class="text-right pass-text">
              <a @click="$router.push({ name: 'MemberForgotPassword' })"
                >Forgot password?
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 25 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.85789 3.76756L7.59273 4.03269C7.41701 4.20841 7.41701 4.49334 7.59273 4.6691L14.9237 12.0001L7.59273 19.3312C7.41701 19.5069 7.41701 19.7918 7.59273 19.9676L7.85789 20.2327C8.03362 20.4084 8.31855 20.4084 8.49427 20.2327L16.4087 12.3183C16.5844 12.1426 16.5844 11.8577 16.4087 11.6819L8.49427 3.76756C8.31855 3.5918 8.03362 3.5918 7.85789 3.76756Z"
                    fill="#0069F3"
                  /></svg
              ></a>
            </p>
          </div>
          <div class="form-button">
            <v-btn class="primary" :disabled="!valid" @click="login()"
              >Login</v-btn
            >
          </div>
        </v-form>
      </div>
      <v-col
        v-if="error.length > 0"
        class="login-page-error-box mt-3 mb-3 col-md-12"
      >
        <v-alert dense outlined type="error">
          {{ error }}
        </v-alert>
      </v-col>
      <div class="login-page-footer">
        Don't have an account
        <span
          class="link-text"
          @click="$router.push({ name: 'MemberRegister' })"
        >
          Register
          <svg
            width="18"
            height="18"
            viewBox="0 0 25 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.85789 3.76756L7.59273 4.03269C7.41701 4.20841 7.41701 4.49334 7.59273 4.6691L14.9237 12.0001L7.59273 19.3312C7.41701 19.5069 7.41701 19.7918 7.59273 19.9676L7.85789 20.2327C8.03362 20.4084 8.31855 20.4084 8.49427 20.2327L16.4087 12.3183C16.5844 12.1426 16.5844 11.8577 16.4087 11.6819L8.49427 3.76756C8.31855 3.5918 8.03362 3.5918 7.85789 3.76756Z"
              fill="#0069F3"
            />
          </svg>
        </span>
      </div>
      <members-plan-modal
        v-if="membersPlanModalStatus"
        @closeMemberPlanModal="closeMemberPlanModal"
        :membersPlanModalStatus="membersPlanModalStatus"
      />
    </div>
  </div>
</template>

<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import MembersPlanModal from "./MembersPlanModal.vue";
import mainHeader from "@/components/Sections/DPCMember/Layout/mainHeader";
import api from "@/services/api";

export default {
  data() {
    return {
      showPassword: false,
      emailExists: true,
      emailVerified: true,
      incorrectPassword: false,
      snackbar: false,
      textInSnackbar: "",
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "This field is required",
        (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "This field is required",
        // (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(v) || "Min. 8 characters with at least one capital letter, a number and a special character.",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      valid: false,
      error: "",
      membersPlanModalStatus: false,
    };
  },
  components: {
    mainHeader,
    MembersPlanModal,
  },
  methods: {
    goToApaly() {
      window.location = "https://apaly.net/login";
    },
    lowerCaseEmail() {
      this.email = this.email.toLowerCase();
    },

    checkEmail() {
      api()
        .get(`/member/app/verify?email=${this.email}`)
        .then((res) => {
          if (res.data.exist === false) {
            this.emailExists = false;
          } else {
            this.emailExists = true;
          }

          if (res.data.verified === false) {
            this.emailVerified = false;
          } else {
            this.emailVerified = true;
          }
        })
        .catch((err) => {});
    },

    resendVerificationLink() {
      api()
        .get(`/member/app/send/verification?email=${this.email}`)
        .then((res) => {
          if (res) {
            this.snackbar = true;
            this.textInSnackbar =
              "Verification link sent to email " + this.email;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    login() {
      this.$refs.formLogin.validate();

      if (this.valid) {
        if (localStorage.getItem("user")) {
          localStorage.removeItem("user");
        }

        if (this.$cookies.get("token")) {
          this.$cookies.remove("token");
        }

        let data = {
          email: this.email,
          password: this.password,
        };

        api()
          .post(`/member/app/login`, data)
          .then((res) => {
            if (res) {
              res.data.type = "dpc-member";
              this.$cookies.set("token", res.data.accessToken);
              localStorage.setItem("user", JSON.stringify(res.data));

              let filterMarket = res.data.filterMarket;
              let companyFilter =
                filterMarket && filterMarket.company
                  ? filterMarket.company
                  : null;

              let url = `/marketplace`;

              if (filterMarket) {
                url += `?${companyFilter ? "company=" + companyFilter : ""}`;
                let locationFilter = filterMarket.location
                  ? filterMarket.location
                  : null;
                if (locationFilter)
                  url += `${
                    companyFilter ? "&" : ""
                  }location=${locationFilter}`;
              }
              if (res.data.nextStep && res.data.nextStep == "confirmPlan") {
                window.location = "/confirm-plan-information";
              } else if (
                res.data.nextStep &&
                res.data.nextStep == "selectDPC"
              ) {
                window.location = url;
              } else if (res.data.nextStep && res.data.nextStep == "complete") {
                window.location = "/home";
              }
            }
          })
          .catch((err) => {
            if (err.response.data) {
              this.error = err.response.data;
            } else {
              this.error = "Something went wrong. Please try again later.";
            }

            this.snackbar = true;
            this.textInSnackbar = err.response.data;
          });
      }
    },

    navigateToConfirmEmail() {
      this.$store.commit("setEmail", this.email);
      this.$router.push("/auth/confirm-email-password");
    },
    closeMemberPlanModal() {
      this.membersPlanModalStatus = false;
    },
  },
  created() {
    localStorage.clear();
    this.$cookies.remove("token");
    // if (this.$matomo && this.$matomo.trackPageView) {
    //   this.$matomo.trackPageView({
    //     customTitle: "Member Login", // Optional
    //   });
    // } else {
    //   console.error("Matomo object or trackPageView method is not defined.");
    // }
  },
};
</script>
<style scoped lang="scss">
.login-page {
  padding: 64px;
}
.apaly-logo {
  width: 160px;
  height: 70px;
}
.login-page-container {
  width: 60%;
  margin: 0 auto;
}
.login-page-logo {
  text-align: center;
}
.login-page-title {
  margin-top: 20px;
  text-align: center;
}
.login-page-footer {
  padding: 0 140px;
  margin-top: 10px;
}
.login-page-error-box {
  padding: 20px 140px;
}
.authentication-page-left-side {
  background-color: #214a82;
  padding: 50px 100px 0px 100px;
  flex-direction: column;
  justify-content: space-between;
  &-image {
    border: 30px solid rgba(255, 255, 255, 0.1);

    border-radius: 10px;
  }
  &-logo {
    img {
      width: 120px;
    }
  }
  &-text {
    color: white;
  }
  &-footer-text {
    color: white;
  }
}
.authentication-page-right-side {
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.authentication-page-right-side-form {
  align-self: center;
  padding: 0 140px;
}
.form-button {
  display: flex;
  justify-content: flex-end;
  .primary {
    flex: 0.5;
  }
}
.link-text {
  color: #0069f3;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.pass-text {
  color: #0069f3;
  font-weight: 700;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .login-page {
    padding: 24px;
  }
  .login-page-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .login-page-container {
    width: 100%;
  }
  .login-page-footer {
    padding: 0;
    margin-top: 30px;
  }
  .login-page-error-box {
    padding: 10px 0;
  }
  .authentication-page-right-side-form {
    padding: 0;
  }
  .authentication-page-right-side {
    flex-direction: column-reverse;
  }
}
</style>