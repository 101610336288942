import { render, staticRenderFns } from "./AddFamilyMember.vue?vue&type=template&id=9a1e1308&scoped=true&"
import script from "./AddFamilyMember.vue?vue&type=script&lang=js&"
export * from "./AddFamilyMember.vue?vue&type=script&lang=js&"
import style0 from "./AddFamilyMember.vue?vue&type=style&index=0&id=9a1e1308&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a1e1308",
  null
  
)

export default component.exports