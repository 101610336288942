<template>
  <div class="mt-2">
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="selectingProvider" />

      <!-- TABLE TITLE -->
      <!-- <v-card-title class="font-36 font-weight-500 mb-0 mt-0 pt-0 pb-2 ml-2">
        {{ tabTitle }}  
      </v-card-title> -->

      <!-- CARDS CONTAINER -->
      <div class="col-12 pa-0 row d-flex ma-1">

        <!-- CARD COLUMN 12 -->
        <div
          v-for="(card, itemIndex) in filteredItems"
          :key="itemIndex"
          class="col-12 col-md-12"
          style="margin: auto; padding-left: 0px;"
        >

          <!-- CARD NON-CLICKABLE -->
          <!-- T.N. if we want to focus on this clinic in the map when clicked use (@click="centerMap(card)") -->
          <v-card class="card-radius v_card_height" :style="resolveMarketplaceBackgroundColor(card)">

            <!-- CARD HEADER -->
            <div class="market_card_header">

              <!-- LOGO -->
              <div class="marketplace_item_logo" :style="`background-image:url(${returnCardImage(card)})`"></div>

              &emsp;

              <!-- DPC Name -->
              <!-- <div class="marketplace-item-content-name" :class="!loggedIn ? 'pt-8' : 'pt-4'"> -->
              <div class="marketplace-item-content-name card_title">
                  {{ returnClinicanName(card.name)  }}
                  <span v-if="card.credentials">, {{ card.credentials }}</span>
              </div>

            </div>

            <!-- CARD -->
            <div class="marketplace_item">

              <div class="market_item_card_layout_div">

                <!-- CARD BODY -->
                <div class="marketplace-item-content">

                  <div class="market_place_content_div1">

                  <!-- IDK what this is -->
                  <!-- <div  class="marketplace-item-content-status d-flex justify-end pt-1">
                    <v-chip 
                      v-if="loggedIn && card.entity == 'location' && card.covered"
                      class=""
                      size="x-small"
                      color="#E6E6E6"
                    >
                      {{  card.covered ? 'Covered' : 'Uncovered' }}
                      <img class="ml-1 mr-1" :src="`/svg/${card.covered ? 'coveredIcon.svg' : 'uncoveredIcon.svg'}`" />
                    </v-chip>
                  </div> -->

                  <!-- DISTANCE CHIP -->
                  <div v-if="card.entity == 'location' && card.distance != null" class="distance_chip">
                    <img class="ml-1 mr-2 card_icon" src="/svg/car.svg"/>
                    &nbsp;{{ distance_format(card.distance) }} miles from you
                  </div>

                  <!-- ADDRESS -->
                  <div v-if="card.entity == 'location'" class="marketplace-item-content-address pt-4 card_icon_text">
                    <img class="mr-2 card_icon" src="/svg/cardLocation.svg" />
                    <p class="ma-0 card_text">{{ card.address }}&nbsp;{{ card.city }},&nbsp;{{ card.state }}&nbsp;{{ card.zipCode }}</p>
                  </div>

                  <!-- Direct Primary Care / Provider -->
                  <!-- <div class="marketplace-item-content-category pt-4">
                    <img class="mr-2" src="/svg/dpcIcon.svg" />
                    <p class="ma-0">{{ card.category }}</p>
                  </div> -->

                  <div v-show="card.entity == 'location'">
                  <!-- <div> -->

                    <!-- APPOINTMENT -->
                    <div class="marketplace-item-content-category pt-4 card_icon_text" v-if="card.newPatients && card.entity == 'location'">
                      <img class="mr-2 card_icon" src="/svg/calendar_alt.svg" />
                      <p class="ma-0 card_text"> Next day appointments typically available.</p>
                    </div>

                    <!-- Accepting New Patient: NO -->
                    <div class="marketplace-item-content-category pt-4 card_icon_text" v-else-if="!card.newPatients && card.entity == 'location'">
                      <img class="mr-2 card_icon" src="/svg/info_circle.svg" />
                      <p class="ma-0 card_text"> Not accepting new patients </p>
                    </div>

                    <!-- PROFILE EXTRA INFO -->
                    <div class="marketplace-item-content-category pt-4 card_icon_text">
                      <img class="mr-2 card_icon" src="/svg/map_marker_smile.svg" />

                      <!-- SEE IF card.name HAS "NICE" -->
                      <p class="ma-0 card_text">{{ card.homeCare == true ? 'In Home Care;':'At-Clinic;'}} Virtual; Text/Chat; Phone; At Work</p>
                    </div>
                    
                  </div>

                  <!-- ICONS -->
                  <div class="">

                    <!-- PROVIDER'S TYPE -->
                    <div :class="`marketplace-item-content-category pt-4 card_icon_text ${card.entity == 'location' ? 'hide_element' : ''}`">
                      <img  class="mr-2 card_icon" src="/svg/notes_medical.svg" />
                      <p class="ma-0 card_text">{{ card.speciality }}</p>
                    </div>

                    <!-- IF PROVIDER; SHOW GENDER -->
                    <div :class="`marketplace-item-content-category pt-4 card_icon_text ${card.entity == 'location' ? 'hide_element' : ''}`">
                      <img  class="mr-2 card_icon" :src="card.entity == 'location' ? '' : card.entity == 'provider' && card.gender == 'F' ?  '/svg/femaleClinician.svg' : '/svg/maleClinician.svg'" />
                      <p class="ma-0 card_text">{{ card.gender == 'F' ? 'Female':'Male' }}</p>
                    </div>

                    <!-- APPOINTMENT -->
                    <div class="marketplace-item-content-category pt-4 card_icon_text" v-if="card.newPatients && card.entity != 'location'">
                      <img class="mr-2 card_icon" src="/svg/calendar_alt.svg" />
                      <p class="ma-0 card_text"> Next day appointments typically available.</p>
                    </div>

                    <!-- Accepting New Patient: NO -->
                    <div class="marketplace-item-content-category pt-4 card_icon_text" v-else-if="!card.newPatients && card.entity != 'location'">
                      <img class="mr-2 card_icon" src="/svg/info_circle.svg" />
                      <p class="ma-0 card_text"> Not accepting new patients </p>
                    </div>

                    <!-- <img  v-if="card.entity == 'location' && card.services.length > 0 && card.services[0] && card.services[0].serviceType == 'Pediatric'" class="ml-1 mr-2" src="/svg/pediatricIcon.svg" />
                    
                    <img  v-if="card.entity == 'location' && !card.newPatients" class="ml-1 mr-2" src="/svg/noNewPatientsIcon.svg" /> -->

                    <!-- PROVIDER'S WORKPLACE -->
                    <div v-if="card.entity == 'provider'" class="marketplace-item-content-works-at pt-4">
                      <p class="ma-0 card_icon_text display_flex_top">
                        <strong class="card_text card_text_nowarp">Works at</strong> &ensp;
                        <span class="card_text">{{ card.worksAt }}</span>
                      </p>
                    </div>

                  </div>

                  

                </div>

                <!-- </div>
                <div class="marketplace-item-actions pa-4" style="margin-top: auto;"> -->

                  <div class="market_place_content_div2">

                    <!-- CARD BUTTONS -->
                    <div class="card_button_container">

                      <!-- Show Membership Status -->
                      <!-- <button class="card_buttons card_button_disabled" v-if="memberInfo?.subscriberRelation == 'Self' && card?.entity == 'location' && memberInfo?.providerId == card?.id && memberInfo?.locationId == card?.locationId && memberInfo?.setup != 'incomplete'" disabled>
                        {{ memberInfo?.subscription?.status == 'active' ? 'Active':'Pending Membership' }}
                      </button > -->

                      <!-- Confirming Eligibility -->
                      <button class="card_buttons" v-if="memberInfo?.nextStep == 'confirmPlan' && card?.entity == 'location' && card?.newPatients && memberInfo?.locationId == card?.locationId"  @click="goToRegister(card.id, card, card.entity)">
                        Confirm eligibility
                      </button>

                      <!-- Select Button -->
                      <button class="card_buttons" v-else-if="card?.entity == 'location' && card?.newPatients"  @click="goToRegister(card.id, card, card.entity)">
                        Select
                      </button>

                      <!-- Join Waitlist -->
                      <div class="card_button_joinwaitlist" v-else-if="!card?.newPatients" @click="joinWaitlist(card.locationId)">
                        Join the waitlist 
                      </div>

                      <!-- View Details -->
                      <button class="card_buttons" @click="goToDetails(card, card.entity)">
                        View Details
                      </button>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </v-card>

        </div>
      </div>

      <!-- Active Subscription -->

      <!-- Enter email to get notified -->
      <v-dialog v-model="notifyMe" width="500px" persistent>

        <v-card class="dialog_layout">
          
          <v-card-title class="d-flex justify-end">
            <v-icon role="button" @click="notifyMe = false" v-text="'mdi-close'" slot="end"></v-icon>
          </v-card-title>

          <v-card-text class="dialog_content">
            <div class="dialog_title dialog_primary" style="color: #333333">
              This location is not accepting new patients at the moment
            </div>
            <div class="dialog_subtext dialog_second">
              Enter your e-mail and well notify you as soon as this clinic is accepting new patients again
            </div>
            <div class="dialog_input">
              <div v-if="!loggedIn" style="font-size: 18px" class="mt-5 mb-3">Email</div>
              <input v-if="!loggedIn" @input="delayCheckmail()" v-model="unregisteredEmail" placeholder="Enter email" dense type="email" style="width:100%" class="marketpalce_notice_input"/>
              <v-checkbox
                class="dialog_checkbox mb-5"
                hide-details
                v-model="allowApalyToContact"
                label="I agree to be contacted by Apaly"
                :true-value="true"
                :false-value="false"
              >
              </v-checkbox>
            </div>
            <div class="dialog_buttons">
              <button class="dialog_single_button dialog_btn_primary" :disabled="!validEmail || !allowApalyToContact" @click="saveEmailAndNotifyMe()" >
                Notify me
              </button>
            </div>
          </v-card-text>

        </v-card>

      </v-dialog>

      <!-- Successfully added to waitlist -->
      <v-dialog v-model="addedToWaitlist" width="500px" persistent>
        <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon
            role="button"
            @click="addedToWaitlist = false"
            v-text="'mdi-close'"
            slot="end"
          ></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="text-center mb-5">
            <img src="/svg/email_sent.svg" />
          </div>
          <div class="dialog_title dialog_primary">You've been added to the waitlist</div>
          <div class="dialog_subtext dialog_second">
            We'll notify you as soon as this clinic is available and accepting new patients.
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary mt-5" @click="reloadMarketpalce()" >
              Find a different provider
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

      <!-- You have an pending/active membership -->
      <v-dialog v-model="cannotEnrollModal" width="500px" persistent>
        <v-card class="dialog_layout">
          <v-card-title class="d-flex justify-end">
            <v-icon role="button" @click="cannotEnrollModal = false" v-text="'mdi-close'" slot="end"></v-icon>
          </v-card-title>

          <v-card-text class="dialog_content">
            <div class="dialog_title dialog_error">
              You have a {{ memberInfo?.subscription?.status }} membership with {{ memberInfo?.location?.name }}
            </div>
            <div class="dialog_subtext dialog_second">
              If you'd like to change your APC clinic, please contact support at
              <strong>support@apaly.com</strong>
            </div>
            <div class="dialog_buttons">
              <button class="dialog_single_button dialog_btn_primary" @click="cannotEnrollModal = false">
                Okay
              </button>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

    <!-- Dependent WITHOUT Custom Link -->
    <v-dialog v-model="dependnetAllSetNoCustomLink" width="500px" persistent>
        <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Great. Family member enrolled
          </div>
          <div class="dialog_subtext dialog_second">
            Would you like to add another family member? 
          </div>
          <div class="dialog_buttons">
            <button class="dialog_double_button dialog_btn_second dialog_btn_floatLeft" @click="goToMembershipCard()">
              I'm all set
            </button>
            <button class="dialog_double_button dialog_btn_primary dialog_btn_floatRight" @click="addDependentAfterEnroll()" >
              Add another 
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dependent WITH Custom Link -->
    <!-- <v-dialog v-model="dependnetAllSetModal" width="500px" persistent>
        <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Family member enrolled
          </div>
          <div class="dialog_subtext dialog_second">
            <strong>{{`${selectedMember.firstName} ${selectedMember.lastName}`}}</strong> has access to {{`${selectedMember.gender == 'f' ? 'her':'his'}`}} own personal provider!
            <br><br>
            Now let's connect you to <strong>{{ selectedProvider.locationName }}</strong> member portal, so you can schedule your family member's first appointment
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="goToCustomRedirect(postMemberEnrollment.redirect)" >
              Connect me now
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- Custom clinic redirect modal (Subscriber's Modal) -->
    <v-dialog v-model="ifOneMedical" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary">
            Success! You're enrolled with {{ selectedProvider.locationName }}
          </div>
          <div class="dialog_subtext dialog_second">
            <!-- {{ postMemberEnrollment !== null ? postMemberEnrollment.message : '' }} -->
            You now have access to your own personal provider! <br> <br>
            Now let's connect you to {{ selectedProvider.locationName }} member portal, so you can create your patient account and schedule your first appointment 
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="goToCustomRedirect(postMemberEnrollment.redirect)" >
              Connect me now
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- NO Custom clinic redirect modal (Subscriber's Modal) -->
    <v-dialog v-model="subscriberAllSetModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="redirect()" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>

        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_primary" >
            Success! You're enrolled with {{ selectedProvider.locationName }}
          </div>
          <div class="dialog_subtext dialog_second">
            You now have access to your own personal provider! <br><br>
            The clinic will reach out to schedule your first appointment.  
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="goToMembershipCard()" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  <!-- You are already enrolled to a provider -->
  <v-dialog v-model="alreadyHaveProvider" width="500px" persistent>
      <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="alreadyHaveProvider = false" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>

      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_primary">
          You're already enrolled with a provider
        </div>
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary" @click="alreadyHaveProvider = false" >
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

    <!-- Dependent error modal -->
    <v-dialog v-model="dependnetErrorModal" width="500px" persistent>
      <v-card class="dialog_layout">
        <v-card-title class="d-flex justify-end">
          <v-icon role="button" @click="dependnetErrorModal = false" v-text="'mdi-close'" slot="end"></v-icon>
        </v-card-title>
        <v-card-text class="dialog_content">
          <div class="dialog_title dialog_error text-center">
            <!-- Employer's plan is not covered by this provider. -->
            {{ errorMessage.message ? errorMessage.message : errorMessage }}
            <div class="dialog_subtext dialog_second">
           Please select another provider
          </div>
          </div>
          <div class="dialog_buttons">
            <button class="dialog_single_button dialog_btn_primary" @click="dependnetErrorModal = false" >
              Okay
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  

  <!-- SELECTING PROVIDER LOADER -->
  <!-- <v-dialog v-model="selectingProvider" width="500px" persistent>

      <v-card class="dialog_layout">

        <v-card-text class="dialog_content">
          <div class="dialog_image">
            <v-progress-circular indeterminate :size="50" color="primary" class="text-center mt-10 mb-5"></v-progress-circular>
          </div>
          <div class="dialog_title dialog_second text-center">
            Selecting Provider, please wait...
          </div>
        </v-card-text>

      </v-card>

  </v-dialog> -->

  <!-- NO EMAIL -->
  <v-dialog v-model="noEmail" width="500px" persistent>
    <v-card class="dialog_layout">
      <v-card-title class="d-flex justify-end">
        <v-icon role="button" @click="noEmail = false" v-text="'mdi-close'" slot="end"></v-icon>
      </v-card-title>
      <v-card-text class="dialog_content">
        <div class="dialog_title dialog_error text-center">
          {{ `${memberInfo.firstName} ${memberInfo.lastName} has no email` }}
        </div>
        <div class="dialog_buttons">
          <button class="dialog_single_button dialog_btn_primary" @click="noEmail = false" >
            Okay
          </button>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>

  <errorModal v-if="somethingWrong" :someThingWentWrong="somethingWrong" :errorMess="errMessage" ref="error_modal" ></errorModal>

  </div>
</template>
<script>
var _paq = window._paq || [];
_paq.push(["trackPageView"]);
import api from "../../../../services/api";
import { mapState } from 'vuex'
import errorModal from '../../../Common/SomethingWentWrong.vue'
import FullscreenLoader from '../../../Common/FullscreenLoader.vue';
import Mixpanel from "mixpanel-browser";

export default {
  props: {
    marketplace: {
      type: Array,
    },
    searchQuery: {
      type: String,
    },
    zipOrState: {
      type: String,
    },
    tabTitle: {
      type: String,
    },
    tab: {
      type: String,
    },
    showMap: {
      type: Boolean,
    },
  },
  components: {
    errorModal,
    FullscreenLoader,
  },
  data() {
    return {
      noEmail: false,
      errMessage: "",
      somethingWrong: false,
      allowApalyToContact: false,
      notifyMe: false,
      addedToWaitlist: false,
      alreadyHaveProvider: false,
      postMemberEnrollment: null,
      ifOneMedical: false,
      dependnetAllSetNoCustomLink: false,
      // userInfo: null,
      cannotEnrollModal: false,
      dependnetAllSetModal: false,
      subscriberAllSetModal: false,
      dependnetErrorModal: false,
      errorMessage: "",
      selectingProvider: false,
      selectedProvider: [],
      selectedMember: [],
      selectedLocationForWaitlist: "",
      unregisteredEmail: "",
      validEmail: false,
      emailRules: [
        (v) => !!v || "This field is required",
        (v) =>
          /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ||
          "Please enter a valid e-mail",
      ],
    };
  },

  created() {
    // if (this.memberInfo && this.$cookies.get("token")) { // See if user is logged in
    //   this.getUserInformation();
    // }
  },
  // beforeRouteUpdate(to, from, next) {
  //   this.getMarketplace();
  //   next();
  // },
  watch: {
    notifyMe(nV, oV) {
      if(nV == false) {
        this.unregisteredEmail = "";
        this.allowApalyToContact  = false;
      }
    },
  },
  computed: {
    ...mapState({
      memberInfo: (state) => state.memberInfo
    }),
    filteredItems() {
      const query = this.searchQuery.toLowerCase().trim();
      if (query === "") {
        return this.marketplace;
      }

      return this.marketplace.filter((item) => {
        const nameIncludesQuery = item.name.toLowerCase().includes(query);
        const specialtyIncludesQuery = item.speciality.toLowerCase().includes(query);

        return nameIncludesQuery || specialtyIncludesQuery;
      });
    },
    loggedIn() {
      return this.memberInfo && this.$cookies.get("token");
    },
  },
  methods: {
    goToMembershipCard() {
      this.$router.push('/membership').then(() => {
        window.location.reload();
      });
    },
    delayCheckmail() {
      // console.log(this.unregisteredEmail);
      clearTimeout(this.typingTimuot);
      this.typingTimuot = setTimeout(() => {
        this.unregisteredEmail = this.unregisteredEmail.trim();
        this.checkEmail();
      }, 500);
    },
    checkEmail() {
      this.unregisteredEmail = this.unregisteredEmail.toLowerCase();

      const isEmailValid = this.emailRules.every(
        (rule) => rule(this.unregisteredEmail) === true
      );
      if (!isEmailValid) {
        this.validEmail = false;
      }
      else {
        this.validEmail = true;
      }
      // console.log("validEmail: ", this.validEmail);
      // console.log("loggedIn: ", this.loggedIn);
    },
    reloadMarketpalce() {
      window.location.reload();
    },
    saveWaitlist(email) {
      let params = {
        email: email,
        location: this.selectedLocationForWaitlist
      }
      // If this.$store.getters.getDependentId has ID (Dependent ID), then use it. Else, use the memberInfo.id (Subscriber's ID)
      const memberID = this.$store.getters.getDependentId ? this.$store.getters.getDependentId : this.memberInfo.id;
      api()
        .post(`/member/app/join/wait/list`, params)
        .then((res) => {
          // console.log("Adding Waitlist Success");
          // console.log(res.data);
          this.addedToWaitlist = true; // IF successful
        })
        .catch((err) => {
          console.log("Adding Waitlist Error");
          console.log(err);
          console.log(err.response.data);
        });
    },
    saveEmailAndNotifyMe() {
      this.saveWaitlist(this.unregisteredEmail);
      this.notifyMe = false;
    },
    joinWaitlist(id) {
      this.selectedLocationForWaitlist = id; // chosen provider

      if(!this.loggedIn) { // If NOT logged in
        // console.log("get user email from input");
        this.notifyMe = true;
      }
      else {
        // console.log("get user email fomr memberInfo");
        if(this.memberInfo.email) {
          this.saveWaitlist(this.memberInfo.email);
        }
        else {
          this.noEmail = true;
        }
      }
    },
    redirect() {
      //Get redirect destination from localStorage
      let redirect = localStorage.getItem('redirect');

      // Get member info
      this.$store.dispatch('getMemberInfo');

      //See where to redirect
      if(redirect == "/home") {
        this.$router.push('/home')
      }
      else if(redirect == "/account/details") {
        this.$router.push('/account/details')
      }
      else {
        // console.log("Redirect is empty: ", redirect);
      }
    },
    confirmEligibility() {
      this.$store.dispatch('getEmployerPlans');
      this.$router.push('/confirm-plan-information'); 
    },
    // goHome() {
    //   this.ifOneMedical = false;
    //   this.$router.push('/home');
    //   // this.$router.push('/home').then(() => {
    //   //   // Reload the page after the navigation is complete
    //   //   // This will make the sidebar appear
    //   //   window.location.reload();
    //   // });
    // },
    // goAccountSettings(){
    //   // window.location = '/home';
    //   this.$router.push('/account/details');
    //   // this.$router.push('/account/details').then(() => {
    //   //   // Reload the page after the navigation is complete
    //   //   // This will make the sidebar appear
    //   //   window.location.reload();
    //   // });
    // },
    goToCustomRedirect(redirect) {
      window.open(redirect, '_blank');
    },
    allSet(){
      // this.subscriberAllSetModal = true;
      this.$store.commit("setDependent", "");
      
      //Dispatch getMemberInfo
      this.$store.dispatch('getMemberInfo');
    },
    allSetDependet(){
      // this.dependnetAllSetModal = false;
      this.dependnetAllSetNoCustomLink = false;
      this.$store.commit("setDependent", "");
      
      //Dispatch getMemberInfo
      this.$store.dispatch('getMemberInfo');
      window.location= '/account/details'
    },
    addDependentAfterEnroll(){
      this.$router.push('/add-family-member');
    },
    // getUserInformation(){
    //   if(this.$store.getters.getDependentId){
    //     api()
    //     .get(`/member/app/family/member/${this.$store.getters.getDependentId}`)
    //     .then((res) => {
    //       if (res) {
    //         this.userInfo = res.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    //   }else{
    //    api()
    //     .get(`/member/app/home`)
    //     .then((res) => {
    //       if (res) {
    //         this.userInfo = res.data;
    //         this.$emit('sendUserInfo', this.userInfo);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });    
    //   }
   
    // },
    distance_format(distance) {
      if(distance) {
        return distance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      }
    },
    goToRegister(dpcID, card, entity) {
      // console.log("Select Provider: ", this.memberInfo);
      // Poppulate Selected Provider

      this.selectedProvider = card;

    //   Selecting provider loader
      this.selectingProvider = true; 

      // See if clinician
      if(entity != 'location') {
          this.goToDetails(card, entity);
      }
      else if(entity == 'location'){
        // Mixpanel Integrations
        let tracking_user_id;
        if(this.memberInfo.id) {  // If logged in
          tracking_user_id = this.memberInfo
        }
        else {
          tracking_user_id = null;
        }
        // Get time stamp with MongoDB timestamp format (2022-03-11T15:30:00.000Z)
        const selecting_provider_timestamp = new Date().toISOString();
        // Track Selected Provider
        Mixpanel.track("member_clinic_selected", {
          user_id: tracking_user_id, 
          timestamp: selecting_provider_timestamp,
          clinic_selected: card,
        });

        // Set dependent id
        if (this.memberInfo.id && this.memberInfo.subscriberRelation === 'Self') {
            this.$store.commit("setDependent", this.memberInfo.id);
        } else if (this.memberInfo.id && this.memberInfo.associatedDependents[0]) {
            this.$store.commit("setDependent", this.memberInfo.associatedDependents[0]);
        }

        // Selecting a provider for the currently logged in account regardless if Self or Dependent
        if(this.loggedIn && this.memberInfo?.nextStep != 'complete'){
          // Sometimes, this.$store.getters.getDependentId is being used to select provider for subscriber
          // If subscriber is being resgistered by dependent as Self
          // So we must also consider the condition
          if(this.$store.getters.getDependentId && this.$store.getters.getDependentId == this.memberInfo.id) {
                // If this.$store.getters.getDependentId is the same as subscriber ID
            this.selectProviderForSubscriber(dpcID, card, entity);
          }
          else {
            // Else, it must be a dependent
            this.selectProviderForDependent(dpcID, card, entity);
          }
        }
        else if(this.$store.getters.getDependentId && this.$store.getters.getDependentId != this.memberInfo.id && this.loggedIn) {
          // console.log("Dependent Flow");
          // For Dependents enrollment ONLY
          this.selectProviderForDependent(dpcID, card, entity);
        }
        else if(this.loggedIn && this.memberInfo?.nextStep == 'complete') {
          this.selectingProvider = false; 
          this.cannotEnrollModal = true;
        }
        // New Users
        else if (!this.loggedIn) {
            // Push back to register when not logged in
            this.$router.push('/')
            return;

          // console.log("Resgiter");
          let params = "";
          if (card.locationId) {
            params = `?locationId=${card.locationId}`;
          }
          api().get(`/member/app/market/provider/${dpcID}` + params)
          .catch((err) => {
            if (err) {
              // console.log('Error: ', err.response.data)
              this.errorMessage = err.response.data;
              this.selectingProvider = false;
              this.dependnetErrorModal = true;
            }
          })
          .then((res) => {

            // Dispatch getMemberInfo
            this.$store.dispatch('getMemberInfo');
            
            if (res) {
              this.provider = res.data;
              this.$store.commit("setSelectedProvider", this.provider);
              this.$router.push("/register");
              this.selectingProvider = false;
            }
            else {
              this.errorMessage = "Failed to get Provider details";
              this.selectingProvider = false;
              this.dependnetErrorModal = true;
            }
          });

        }

      }
      else {
        // console.log("memberInfo: ", this.memberInfo);
        // console.log("Dependent ID: ", this.$store.getters.getDependentId);
        // console.log("DPC ID: ", dpcID);
        // console.log("card", card);
        // console.log("entity", entity);

        // console.log('Error',err.response.data)
        this.errorMessage = "Oops. Seems like you selected a non-provider card.";
        this.selectingProvider = false;
        this.dependnetErrorModal = true;
      }
    },
    selectProviderForSubscriber(dpcID, card, entity) {
      // console.log("Select Provider for Subscriber");
      // console.log("memberInfo: ", this.memberInfo);  
      // console.log("Dependent ID: ", this.$store.getters.getDependentId);
      // console.log("DPC ID: ", dpcID);
      // console.log("card", card);
      // console.log("entity", entity);
      this.$store.commit("setSelectedProvider", card);

      // This is when subscriber failed the eligibility test before so they are already logged in for this

      // if((this.memberInfo && this.memberInfo.providerId != dpcID) || (this.memberInfo && this.memberInfo.locationId != card.locationId)){
      if(this.memberInfo){
        // console.log("this.memberInfo.setup: ", this.memberInfo.setup);
        if(this.memberInfo.setup == 'incomplete') {
          let data = {
            providerId: card.id,
            locationId: card.locationId,
          };
        api()
        .post("/member/app/set/provider", data)
        .catch((err) => {
          if (err) {
            console.log('Error',err.response.data)
            this.errorMessage = err.response.data;
            this.selectingProvider = false;
            this.dependnetErrorModal = true;
          }
          })
          .then(async (res) => {
            if(res) {
              //Dispatch getMemberInfo
              await this.$store.dispatch('getMemberInfo');
              // If user's eligibility confirmation isn't complete,
              // then user must be able to confirm their eligibility, again.
              // This logic will also serve the first time eligibility check
              if (this.memberInfo && (this.memberInfo.nextStep == 'selectDPC')) {
                this.selectingProvider = false;
                this.$store.dispatch('getEmployerPlans');
                // this.$router.push('/confirm-plan-information');   
                this.$router.push('/confirm-plan-information').then(() => {
                  window.location.reload();
                });
              }     
              // If user's eligibility confirmation is complete,
              // then we show this modal
              else {
                this.selectingProvider = false;
                this.subscriberAllSetModal = true;
              }
            }
          });
          return
        }
        // else if eligibility is successful, then user shouldn't be able to enroll to another provider
        else if(this.memberInfo.setup == 'complete'){
          this.selectingProvider = false;
          this.cannotEnrollModal = true;
          // return
        }
        else {
          // console.log(`Selecting for ${this.memberInfo.firstName} ${this.memberInfo.lastName}'s provider failed because the flow went to Subscriber's flow.`)
          this.errorMessage = `Something went wrong while selecting for ${this.memberInfo.firstName} ${this.memberInfo.lastName}'s provider.`;
          this.selectingProvider = false;
          this.dependnetErrorModal = true
        }
      }
    },
    selectProviderForDependent(dpcID, card, entity) {
      // console.log("Select Provider for Dependent"); 
      // console.log("Dependent ID: ", this.$store.getters.getDependentId);
      // console.log("DPC ID: ", dpcID);
      // console.log("card", card);
      // console.log("entity", entity);

      // memberInfo IS NOT RELEVANT HERE because this is for selecting a provider for a dependent

      let params = "";
      if (card.locationId) {
        params = `?locationId=${card.locationId}`;
      }
      this.$store.commit("setSelectedProvider", card);

      let data = {
        providerId: card.id,
        locationId: card.locationId,
      };
      // Seleciting provider for dependent
      if(this.$store.getters.getDependentId){        
        // console.log("dependent ID", this.$store.getters.getDependentId);      
        api().post(`/member/app/family/member/${this.$store.getters.getDependentId}/set/provider`,  data)
        .catch((err) => {
          if (err) {
            // console.log('Dependent Enrollment Error : ', err.response.data)
            this.errorMessage = err.response.data;
            this.selectingProvider = false;
            this.dependnetErrorModal = true

            // Empty Dependent ID from Store
            // this.$store.commit("setDependent", "");
          }
        })
        .then((res) => {
          if (res) {
            this.selectingProvider = false;

            // Get Member Info
            api().get(`/member/app/family/member/${this.$store.getters.getDependentId}`)
            .then((res) => {
              this.selectedMember = res.data
              // console.log("this.selectedMember", this.selectedMember);
            })
            .catch((err) => {
              alert(err.response.data);
            });

            // Check if select provider has Custom Link
            this.checkCustomModal(card.id);

            // Empty Dependent ID from Store
            // this.$store.commit("setDependent", "");

            //Dispatch getMemberInfo
            this.$store.dispatch('getMemberInfo');
          }
        });
      }
      // If no dependent ID was found
      else {
        // console.log('Dependent Error 2: ', err.response.data)
        this.errorMessage = "No Dependent ID was found";
        this.selectingProvider = false;
        this.dependnetErrorModal = true;
      }

      // api().get(`/member/app/market/provider/${dpcID}` + params).then((res) => {
      //   if (res) {

      //     this.provider = res.data;
      //     this.$store.commit("setSelectedProvider", this.provider);
          
      //     // else {
      //     //   if(this.memberInfo && this.memberInfo.nextStep == 'complete') {
      //     //     this.errorMessage = `${this.memberInfo.firstName} ${this.memberInfo.lastName} is already enrolled to ${this.memberInfo.location.name}.`;
      //     //     this.selectingProvider = false;
      //     //     this.dependnetErrorModal = true
      //     //   }
      //     //   else if(this.memberInfo && this.memberInfo.nextStep == 'confirmPlan') {
      //     //     this.errorMessage = `${this.memberInfo.firstName} ${this.memberInfo.lastName} needs to go through Eligibility Checking.`;
      //     //     this.selectingProvider = false;
      //     //     this.dependnetErrorModal = true
      //     //   }
      //     // }
          
          
          
      //   }
      //   else {
      //     this.errorMessage = "Provider not found";
      //     this.selectingProvider = false;
      //     this.dependnetErrorModal = true
      //   }
      // });
    },
    checkCustomModal(providerID) {
      // Hide the current modal first
      // this.dependnetAllSetModal = false;

      // console.log("checkCustomModal");
      // console.log(dpcID);
      // console.log("Provider Info: ", card[0]);
      // console.log("Provider ID: ", providerID);
      // console.log("User Info: ", card);

      api()
      .get(`/member/app/provider/${providerID}/postenrollment`) // dpcID is teh same as providerId
      .catch((err) => {
        // We should handle error, soon
        // Ex. There was an error while retrieving your post enrollment data.
        // Please contact support, thank you.
        // console.log(err);

        // TEMPORARY: If there's an error, go to default function
        this.allSet();
        // this.$swal(err.response.data, "", "error");
        this.somethingWrong = true;
      })
      .then((res) => {
        console.log(res);
        if(res) {
          if(res.data.postMemberEnrollment !== null) {
            // If selected provider has Custom Link
            this.postMemberEnrollment = res.data.postMemberEnrollment;
            // console.log("With Redirect link");

            if(this.$store.getters.getDependentId && this.$store.getters.getDependentId != this.memberInfo.id){
              // Selecting provider for dependents
              // this.dependnetAllSetModal = true; // Dependent's Modal
              this.dependnetAllSetNoCustomLink = true; // So user can be redirected to Membership Card page
            }
            else if(this.$store.getters.getDependentId == this.memberInfo.id && this.loggedIn) {
              // Selecting provider for current account regardless if dependent or subscriber account
              // this.ifOneMedical = true; // Subscriber's Modal
              this.subscriberAllSetModal = true; // So user can be redirected to Membership Card page
            }
            else { 
              // Selecting provider for self 
              // this.ifOneMedical = true; // Subscriber's Modal 
              this.subscriberAllSetModal = true; // So user can be redirected to Membership Card page
            }
          }
          else {
            // console.log("else 1");
            // If custom modal isn't needed to be displayed
            if(this.$store.getters.getDependentId && this.$store.getters.getDependentId != this.memberInfo.id){ 
              // Selecting provider for dependents 
              this.dependnetAllSetNoCustomLink = true; // Dependent's Modal
            } 
            else if(this.$store.getters.getDependentId == this.memberInfo.id && this.loggedIn) {
              // Selecting provider for current account regardless if dependent or subscriber account
              this.subscriberAllSetModal = true;  // Subscriber's Modal
            }
            else {
              // Selecting provider for self
              this.subscriberAllSetModal = true;  // Subscriber's Modal
            }
            
          }
        }
        else {
          // console.log("else 2");
          // If custom modal isn't needed to be displayed
          if(this.$store.getters.getDependentId){
              this.allSetDependet();
            }else{
             this.allSet(); 
            }
        }
      });

      // From here, we should detect if user is enrolling their members to different providers
      // if((this.userInfo && this.userInfo.providerId != card.id) || (this.userInfo && this.userInfo.locationId != card.locationId)){
        
      // }
      // else {
      //   console.log('else 3');
      //   this.allSet();
      // }
      
    },
    refresh(){
      this.$store.commit("setDependent", "");
      window.location.reload();
    },
    changeTab(tab) {
      this.tab = tab;
    },
    resolveMarketplaceBackgroundColor(card) {
      if (card.entity == "location") {
        return "border-left: 15px solid #172E4D; border-radius: 8px 0 0 8px";
      } else {
        return "border-left: 15px solid #249999; border-radius: 8px 0 0 8px";
      }
    },
    goToDetails(item, entity) {
      // console.log(item);
      // alert();
      if (entity == "solution") {
        this.$router.push(`/marketplace/solutions/details/${item.id}`);
      } else if (entity == "location") {
        this.$store.state.documentTitle = item.name;
        this.$router.push({
          path: `/marketplace/provider/details/${item.id}`,
          query: { location: item.locationId },
        });
      } else if (entity == "vendor") {
        this.$router.push(`/marketplace/vendor/details/${item.id}`);
      } else if (entity == "provider") {
        this.$router.push(`/marketplace/clinician/details/${item.id}`);
      }
      this.showOverlay = false;
    },
    returnClinicanName(inputString){
  // Split the input string into an array of words
        const words = inputString.split(' ');

        // Capitalize the first letter of each word
        const capitalizedWords = words.map(word => {
          // Ensure the word is not an empty string
          if (word.length === 0) return word;

          // Capitalize the first letter and make the rest of the letters lowercase
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        });

        // Join the capitalized words back into a string
        const capitalizedString = capitalizedWords.join(' ');

        return capitalizedString;

    },
    returnCardImage(card) {
      if (card.logo) {
        return card.logo;
      } else {
        if (card.entity == "location") {
          return "/tpa/clinicsAndOrganizations.svg";
        } else if (card.entity == "vendor") {
          return "/tpa/pointSolutionVendor.svg";
        } else if (card.entity == "provider") {
          if (card.gender == "F") {
            return "/svg/clinicianCard.svg";
          } else if (card.gender == "M") {
            return "/svg/clinicianCardMale.svg";
          }
        }
      }
    },

    // Added by T.N. to center map to this location
    centerMap(card){
      if(card.entity != 'location')return
      if(card.locationId)this.$emit('centerMap', card.locationId)
    }
  },
};
</script>

<style scoped lang="scss">
.text-ellipsis {
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
