<template>
  <div class="home_main_container">
  <!-- <div class="home_main_container" id="scroller"> -->
    <!-- Fullscreen Loader -->
    <FullscreenLoader v-if="loader" />

    <!-- BLUE HEADER -->
    <div class="home-page-header">
      <span class="home-page-title">Welcome</span>
      <span class="home-page-subtitle" v-if="memberInfo?.firstName && memberInfo?.lastName">
        {{memberInfo?.firstName + ' ' + memberInfo?.lastName}}
      </span>
    </div>
    <!-- NEW CONTENT -->
    <div class="home_contents">

      <!-- My Advanced Primary Care -->
      <div class="home_content_cards">
        <div class="home_card_title">My Advanced Primary Care</div>
        <!-- If user needs to confirm eligibility -->
        <div class="home_card_provider" v-if="memberInfo?.location">
          <img 
            class="home_card_provider_img" 
            :src=" memberInfo?.location.logo ? memberInfo?.location.logo : '/tpa/clinicsAndOrganizations.svg'" 
          />
          <div class="home-card_provider_details">
            <div class="home_card_provider_name">{{ memberInfo?.location.name }}</div>
            <div class="home_card_provider_icontext">
              <div class="home_card_provider_icon"><img src="/svg/cardLocation.svg" /></div>
              <div class="home_card_provider_text">
                {{ memberInfo?.location?.address1 }},&nbsp;{{ memberInfo?.location.state }},&nbsp;{{ memberInfo?.location.zip }}
              </div>
            </div>
          </div>
        </div>
        <div class="dialog_buttons mt-5" v-if="memberInfo?.nextStep == 'selectDPC' || memberInfo?.providerPortal">
          <!-- If nextStep is confirmPlan and provider has no custom url -->
          <!-- <div 
            v-if="memberInfo.nextStep == 'confirmPlan'" 
            class="home_card_value_cont1"
            @click="reconfirmEligibility()"
          >
            Confirm Eligibility
          </div> -->

          <!-- If nextStep is confirmPlan and provider has no custom url -->
          <div 
            v-if="memberInfo?.nextStep == 'selectDPC'" 
            class="home_card_value_cont1"
            style="float: right; margin-top: -40px;"
            @click="selectProvider()"
          >
            Select Provider
          </div>

          <!-- If nextStep is complete and provider HAS custom url -->
          <button 
            v-if="memberInfo?.nextStep == 'complete' && memberInfo?.providerPortal" 
            class="dialog_single_button dialog_btn_second dialog_btn_floatLeft" 
            style="width: fit-content !important"
            @click="openCustomLink()"
          >
            Access my provider member portal
          </button>
        </div>
      </div>

      <!-- Subscription -->
      <div class="home_content_cards">
        <div class="home_card_row">
          <div class="home_card_col">
            <div class="home_card_text">Membership Status</div>
          </div>
          <div class="home_card_col">
            <div class="home_card_value">
              <div 
                v-if="memberInfo?.nextStep == 'confirmPlan'" 
                class="home_card_value_cont1" 
                style="float:right" 
                @click="reconfirmEligibility()"
              >
                Confirm Eligibility
              </div>
              <div 
                v-if="memberInfo?.nextStep == 'selectDPC'" 
                style="float:right;font-weight: bold;" 
              >
                -
              </div>
              <div class="home_card_value_status" style="float:right" v-else-if="memberInfo?.nextStep == 'complete'">
                <div class="status_dot" :style="`color:${statusColor(memberInfo?.subscription?.status)};`">&bullet;</div> &nbsp;
                <!-- {{ memberInfo.subscription?.status ? memberInfo.subscription?.status.charAt(0).toUpperCase() + memberInfo.subscription?.status.slice(1) : '-' }} -->
                {{ statusText(memberInfo?.subscription) }}
              </div>
            </div>
          </div>
        </div>

        <div class="home_card_row">
          <div class="home_card_col">
            <div class="home_card_text">Membership Valid Until</div>
          </div>
          <div class="home_card_col">
            <div class="home_card_value" style="float:right;font-weight: bold;">
              {{ memberInfo.subscription?.expire ? returnFormattedDate(memberInfo?.subscription?.expire) : '-' }}
            </div>
          </div>
        </div>
      </div>

      <!-- Insurance Info -->
      <div class="home_content_cards">

        <div class="home_card_title">Insurance Information</div>

        <div class="home_card_row">
          <div class="home_card_col">
            <div class="home_card_label">Member ID</div>
            <div class="home_card_value" v-if="memberInfo?.memberId" >{{ memberInfo?.memberId ?? '-' }}</div>
          </div>
          <div class="home_card_col">
            <div class="home_card_label">Plan</div>
            <div class="home_card_value" v-if="memberInfo?.groupId" >{{ memberInfo?.groupId ?? '-'  }}</div>
          </div>
        </div>

        <div class="home_card_row">
          <div class="home_card_col">
            <div class="home_card_label">Payer ID</div>
            <div class="home_card_value" v-if="memberInfo?.payerId" >{{ memberInfo?.payerId ?? '-'  }}</div>
          </div>
          <div class="home_card_col">
            <div class="home_card_label">Plan Eligibility Status</div>
            <div class="home_card_value">
              <div 
                class="home_card_value_cont1" 
                v-if="memberInfo?.nextStep == 'confirmPlan'"
                @click="reconfirmEligibility()"
              >
                Confirm Eligibility
              </div>
              <div 
                v-if="memberInfo?.nextStep == 'selectDPC'" 
                style="float:right;font-weight: bold;" 
              >
              </div>
              <div class="home_card_value_status" v-else-if="memberInfo?.nextStep == 'complete'">
                <div class="status_dot" :style="`color:${statusColor(memberInfo?.eligibility?.status)};`">&bullet;</div> &nbsp;
                {{ statusText(memberInfo?.eligibility) }}
              </div>
            </div>
          </div>
        </div>

        <div class="home_card_row">
          <div class="home_card_col">
            <div class="home_card_label">Employer</div>
            <div class="home_card_value" v-if="memberInfo?.companyName" >{{memberInfo?.companyName ?? '-'}}</div>
          </div>
        </div>

        <div class="home_card_row">
          <div class="home_card_col">
            <div class="home_card_label">Relationship to Subscriber</div>
            <div class="home_card_value" v-if="memberInfo?.subscriberRelation" >{{memberInfo?.subscriberRelation ?? '-'}}</div>
          </div>
        </div>

        <!-- <div class="home_card_row">
          <div class="home_card_col">
            <div class="dialog_buttons">
              <button class="dialog_single_button dialog_btn_second dialog_btn_floatLeft" style="width: fit-content !important">
                Update Info
              </button>
            </div>
          </div>
        </div> -->

      </div>

      <!-- Extra Space -->
      <div style="height: 50px;"></div>

      <!-- DIALOGS -->

      <!-- Generic Error Modal -->
      <v-dialog v-model="genError" width="500px" persistent>
        <v-card class="dialog_layout">
          <v-card-title class="d-flex justify-end">
            <v-icon role="button" @click="genError = false" v-text="'mdi-close'" slot="end"></v-icon>
          </v-card-title>
          <v-card-text class="dialog_content">
            <div class="dialog_title dialog_error text-center">
              {{ genErrorMess ? genErrorMess:'Oops. Something went wrong' }}
            </div>
            <div class="dialog_buttons">
              <button class="dialog_single_button dialog_btn_primary" @click="genError = false" >
                Okay
              </button>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>
import MembersPlanModal from "../Auth/MembersPlanModal.vue";
import headerMenu from "@/components/Sections/DPCMember/Layout/headerMenu";
import api from "@/services/api";
import moment from "moment";
import { mapState } from "vuex";
import FullscreenLoader from "@/components/Common/FullscreenLoader";
export default {
  components: {
    headerMenu,
    MembersPlanModal,
    FullscreenLoader
  },
  data() {
    return {
      loader: true,
      genError: false,
      genErrorMess: "",
      // checking: false, // This is unused K.N
      confirmed: false,
      notConfirmed: false,
      // checkingEligibility: false, // This is unused K.N
      homeInfo: {},
      member: {},
      // membersPlanModalStatus: false, // This is unused K.N
      // confirmInformationModal: false, // This is unused K.N
      // confirmInformation: true,  // This is unused K.N
      // validInfo: false,  // This is unused K.N
      // validInsurance: false,  // This is unused K.N
      // memberInfo: {},
      // eligibilityStep: 1,
      // genders: [ // Unused K.N
      //   { text: "Female", value: "f" },
      //   { text: "Male", value: "m" },
      // ],
      // relationships: [ // Unused K.N
      //   { text: "Self", value: "Self" },
      //   { text: "Spouse", value: "Spouse" },
      //   { text: "Dependen", value: "Dependent" },
      // ],
      // IdRules: [ // Unused K.N
      //   (v) => !!v || "This field is required",
      //   (v) =>
      //     !!/[0-9]{3}\-[0-9]{6}/.test(v) ||
      //     "ID should contain 9 digits, Please enter your ID in XXX-XXXXXX format",
      // ],
      // requiredRules: [(v) => !!v || "This field is required"], // Unused K.N
      // phoneRules: [ // Unused K.N
      //   (v) =>
      //     /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
      //     "Phone number must be in a valid format (XXX XXX XXXX)",
      // ],
    };
  },
  methods: {
    selectProvider() {
      this.$store.commit("setDependent", this.memberInfo.id);
      this.$store.dispatch('getMarketplace', this.$route.query); // K.N idk what this.$route.query is for
      this.$router.push("/marketplace");
    },
    statusText(sub) {
      if(sub?.status == "pending") return "Pending first visit"
      else if(sub?.status == "active") return "Active" 
      else if(sub?.status == "inactive") return "Inactive" 
      else return "Error"
    },
    statusColor(status) {
      if(status == "pending") return "#FFD400" // Yellow
      else if(status == "active") return "#04D900" // Green 
      else if(status == "inactive") return "#D90101" // Red 
      else return "#CCCCCC" // Gray
    },
    openCustomLink() {
      if(this.memberInfo && this.memberInfo.providerPortal) {
        window.open(this.memberInfo.providerPortal, '_blank');
      }
    },
    reconfirmEligibility() {
      this.$store.dispatch('getEmployerPlans'); 
      this.$router.push('/confirm-plan-information');   
    },
    // getMember() {
    //   api()
    //     .get(`/member/app/member/info`)
    //     .then((res) => {
    //       if (res) {
    //         this.member = res.data;
    //         this.memberInfo = res.data;
    //         this.member.dob = res.data.dob.substring(10, 0);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // },
    // getInformation() {
    //   api()
    //     .get(`/member/app/home`)
    //     .then((res) => {
    //       if (res) {
    //         this.homeInfo = res.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // },
    // closeMemberPlanModal() {
    //   this.membersPlanModalStatus = false;
    // },
    // openConfirmInformationModal() {
    //   this.confirmInformationModal = true;
    // },
    // nextEligibilityStep() { // Unused method K.N
    //   this.eligibilityStep = 2;
    // },
    returnFormattedDate(date){
      return moment.utc(date).format("MM/DD/YYYY");
    },
    // confirm() { // This is unused method K.N
    //   // this.membersPlanModalStatus = false;
    //   // this.checkingEligibility = true;
    //   // this.checking = true;
    //   // this.confirmInformationModal = false;
    //   api()
    //     .post(`/member/app/confirm/info`, this.memberInfo)
    //     .then((res) => {
    //       if (res) {
    //         // this.checking = false;
    //         if (res.data.eligible == true) {
    //           this.confirmed = true;
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       if (err) {
    //         // this.checking = false;
    //         this.notConfirmed = true;
    //       }
    //     });
    // },
    // selectedPlan(data) {
    //   this.memberInfo.planId = data;
    //   this.confirmInformationModal = true;
    // },
    openConfirm(data) {
      console.log("confirmed", data);
      // this.confirmInformationModal = data;
    },
  },
  mounted() {
    // Redirect
    localStorage.setItem('redirect', '/home');

    // SCROLLER
    // The idea is to programmatically detect if home container's contents are overflowing
    // and if so, the home should be scrollable
    // let home_height = document.getElementById("scroller").clientHeight;
    // let home_width = document.getElementById("scroller").clientWidth;
    // let home_div = document.getElementById("scroller");

    // if(home_height > 676) home_div.style.overflowY = 'auto';
    // else if(home_width < 640) home_div.style.overflowY = 'auto'; 
    // else home_div.style.overflowY = 'hidden';
  },
  created() {
    // Clear Cards
    localStorage.removeItem('cards');
    
    // this.getMember(); // We will no longer do this
    // this.getInformation(); // We will no longer do this
    // this.$matomo.trackPageView({
    //   customTitle: 'Member Home Screen', // Optional
    // });
    
    // If memberInfo is empty, then go to login
    if(!this.memberInfo && !this.$cookies.get("token")) {
      this.$router.push("/login");
    }

    // See if memberInfo has value after 3 seconds 
    setTimeout(() => {
      if(!this.memberInfo.id) {
        this.loader = false;
        this.genError = true;
        this.genErrorMess = "Sorry, something went wrong while fetching your information."
        this.$router.push("/login");
      }
      else {
        this.loader = false;
      }
    }, 3000);
  },
  computed: {
    ...mapState({
      memberInfo: (state) => state.memberInfo 
    }),
  }
};
</script>

<style scoped lang="scss">

.home-page-header{
  background-color: #194680;
  height: 150px;
  display: flex;
  flex-direction: column;
  color: white;
  padding:20px 40px
}
.home-page-title{
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}
.home-page-subtitle{
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}
.home-page-content{
  padding:0 40px;
  margin:-50px 0
}
.card-information{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.card-information:last-child{
  margin-bottom: 0px;
}
.card-information-header{
  display: flex;
  justify-content: space-between;
}
.card-information-title{
  font-size: 16px;
  margin-bottom: 10px;

}
@media screen and (max-width: 600px) {
  .home-page-content{
    padding:0 16px;
    margin:-40px 0
  }
  .home-page-header{
    height: 200px;
    justify-content: center;
    padding:20px 16px
  }
}
</style>
